import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import config from "./config";
import Amplify from "aws-amplify";

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "chani-natal-chart",
        endpoint: config.apiGateway.DEV_CHANI_CHART_BACKEND_URL,
        region: config.apiGateway.REGION
      },
      {
        name: "mailchimp",
        endpoint: config.apiGateway.CHANI_APP_LAMBDAS_URL,
        region: config.apiGateway.REGION
      }
    ]
  }
});

ReactDOM.render(<App />, document.getElementById("root"));
