import React, { Component } from "react";
import "./App.css";
import Chart from "./Pages/Chart";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga4";

mixpanel.init("21d124c0cfbe667fc79bf0ec07183a1d");

ReactGA.initialize([{trackingId: "G-GD3M1V2DEF"}]);
class App extends Component {
  componentDidMount() {
    mixpanel.track("User Landed on My Chart Page");
  }
  render() {
    return (
      <div className="App">
        <Chart />
      </div>
    );
  }
}

export default App;
