import React, { Component } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { FormControl, Panel, PanelGroup } from "react-bootstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { API } from "aws-amplify";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga4";
require("dotenv").config();
const apiURL = 'https://chart.chaninicholas.com/interactive-wheel-chart/apiCall.php'
// const apiURL = 'http://localhost:8000/apiCall.php'
mixpanel.init("21d124c0cfbe667fc79bf0ec07183a1d");
const houses = [
  {
    label: "Whole Sign (what I use)",
    value: "whole_sign"
  },
  {
    label: "Placidus",
    value: "placidus"
  },
  {
    label: "Campanus",
    value: "campanus"
  },
  {
    label: "Horizon",
    value: "horizontal"
  },
  {
    label: "Koch",
    value: "koch"
  },
  {
    label: "Porphyry",
    value: "porphyry"
  },
  {
    label: "Topocentric",
    value: "topocentric"
  },
  {
    label: "Equal",
    value: "equal"
  },
];

class Chart extends Component {
  constructor() {
    super();
    this.state = {
      window_size: 750,
      lat: 19.076,
      lon: 72.8777,
      tzone: 5.5,
      getTimezone: false,
      house_type: "whole_sign",
      day: "",
      name: "",
      email: "",
      loadingEmail: false,
      month: "",
      year: "",
      min: "",
      hour: "",
      am_pm: "AM",
      planets: "",
      address: "",
      loading: false,
      showChart: false,
      loadingChart: false,
      sentEmail: false,
      aspectSextileArray: ["No planets sextile your Sun"],
      aspectTrineArray: ["No planets trine your Sun"],
      aspectSquareArray: ["No planets square your Sun"],
      aspectOpposeArray: ["No planets oppose your Sun"],
      aspectConjunctArray: ["No planets conjunct your Sun"],
      aspectMoonSextileArray: ["No planets sextile your Moon"],
      aspectMoonTrineArray: ["No planets trine your Moon"],
      aspectMoonSquareArray: ["No planets square your Moon"],
      aspectMoonOpposeArray: ["No planets oppose your Moon"],
      aspectMoonConjunctArray: ["No planets conjunct your Moon"],
      aspectAscendantSextileArray: ["No planets sextile your Ascendant"],
      aspectAscendantTrineArray: ["No planets trine your Ascendant"],
      aspectAscendantSquareArray: ["No planets square your Ascendant"],
      aspectAscendantOpposeArray: ["No planets oppose your Ascendant"],
      aspectAscendantConjunctArray: ["No planets conjunct your Ascendant"],
      risingAspect: ["No other planets in the 1st house"],
    };
  }

  editBirthInfo = () => {
    this.setState({
      showChart: false,
      loadingChart: false,
      planets: "",
      aspects: "",
    });    
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: Number(e.target.value),
    });
  };

  handleDateChange = (e) => {
    var event = e.target.value;
    var eventArray = event.split("-");
    this.setState({
      year: eventArray[0],
      month: eventArray[1],
      day: eventArray[2],
    });
  };

  handleTimeChange = (time) => {
    var event = time;
    var eventArray = event.split(":");
    this.setState({
      hour: eventArray[0],
      min: eventArray[1],
    });
  };

  handleTextChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  chartEmail = (e) => {
    this.setState({
      loadingEmail: true,
    });
    var data = {
      day: this.state.day,
      month: this.state.month,
      year: this.state.year,
      hour: this.state.hour,
      min: this.state.min,
      lat: this.state.lat,
      lon: this.state.lon,
      tzone: this.state.tzone,
      house_type: this.state.house_type,
      theme_name: "CUSTOM_CHART_THEME_ONE",
      format: "png",
    };
    //console.log(data);

    fetch(apiURL, {
      method: 'POST',
      body: JSON.stringify({
        api_name: 'natal_wheel_chart',
        data: data
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        var output = response.json();
        return output;
      })
      .then((output) => {
        this.setState({
          chartDownload: output.chart_url,
        });
        API.post("chani-natal-chart", "senduseremail", {
          body: {
            chartDownload: output.chart_url,
            userData: this.state,
          },
        })
          .then((response) => {
            this.setState({
              sentEmail: true,
              loadingEmail: false,
            });
            mixpanel.track("User Sent PDF Email");
          })
          .catch((error) => {
            console.log(error);
            this.setState({ loadingEmail: false });
          });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loadingEmail: false });
      });
  };

  downloadChart = () => {
    var data = {
      day: this.state.day,
      month: this.state.month,
      year: this.state.year,
      hour: this.state.hour,
      min: this.state.min,
      lat: this.state.lat,
      lon: this.state.lon,
      tzone: this.state.tzone,
      house_type: this.state.house_type,
      theme_name: "CUSTOM_CHART_THEME_ONE",
      format: "png",
    };
    fetch(apiURL, {
      method: 'POST',
      body: JSON.stringify({
        api_name: 'natal_wheel_chart',
        data: data
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        var output = response.json();
        return output;
      })
      .then((output) => {
        //console.log(output.chart_url);
        this.setState({
          chartDownload: output.chart_url,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.setState({
      window_size: window.screen.width,
    });
  }

  calculateSunAspects = (planets) => {
    var aspectSextileArray = [];
    var aspectTrineArray = [];
    var aspectSquareArray = [];
    var aspectOpposeArray = [];
    var aspectConjunctArray = [];

    for (var i = 0; i < planets.length; i++) {
      if (planets[i].type === "Sextile") {
        if (
          planets[i].aspecting_planet === "Sun" &&
          planets[i].aspected_planet !== "Venus"
        ) {
          aspectSextileArray.push(
            planets[i].aspecting_planet +
              " sextiles " +
              planets[i].aspected_planet
          );
        }
      }
      if (planets[i].type === "Trine") {
        if (planets[i].aspecting_planet === "Sun") {
          aspectTrineArray.push(
            planets[i].aspecting_planet +
              " trines " +
              planets[i].aspected_planet
          );
        }
      }
      if (planets[i].type === "Square") {
        if (planets[i].aspecting_planet === "Sun") {
          aspectSquareArray.push(
            planets[i].aspecting_planet +
              " squares " +
              planets[i].aspected_planet
          );
        }
      }
      if (planets[i].type === "Opposition") {
        if (planets[i].aspecting_planet === "Sun") {
          aspectOpposeArray.push(
            planets[i].aspecting_planet +
              " opposes " +
              planets[i].aspected_planet
          );
        }
      }
      if (planets[i].type === "Conjunction") {
        if (planets[i].aspecting_planet === "Sun") {
          aspectConjunctArray.push(
            planets[i].aspecting_planet +
              " conjuncts " +
              planets[i].aspected_planet
          );
        }
      }
    }

    if (aspectSextileArray.length === 0) {
      aspectSextileArray = this.state.aspectSextileArray;
    }
    if (aspectTrineArray.length === 0) {
      aspectTrineArray = this.state.aspectTrineArray;
    }
    if (aspectSquareArray.length === 0) {
      aspectSquareArray = this.state.aspectSquareArray;
    }
    if (aspectOpposeArray.length === 0) {
      aspectOpposeArray = this.state.aspectOpposeArray;
    }
    if (aspectConjunctArray.length === 0) {
      aspectConjunctArray = this.state.aspectConjunctArray;
    }

    this.setState({
      aspectSextileArray: aspectSextileArray,
      aspectTrineArray: aspectTrineArray,
      aspectSquareArray: aspectSquareArray,
      aspectOpposeArray: aspectOpposeArray,
      aspectConjunctArray: aspectConjunctArray,
    });
  };

  calculateMoonAspects = (planets) => {
    var aspectMoonSextileArray = [];
    var aspectMoonTrineArray = [];
    var aspectMoonSquareArray = [];
    var aspectMoonOpposeArray = [];
    var aspectMoonConjunctArray = [];

    for (var i = 0; i < planets.length; i++) {
      if (planets[i].type === "Sextile") {
        if (planets[i].aspecting_planet === "Moon") {
          aspectMoonSextileArray.push(
            planets[i].aspecting_planet +
              " sextiles " +
              planets[i].aspected_planet
          );
        }
      }
      if (planets[i].type === "Trine") {
        if (planets[i].aspecting_planet === "Moon") {
          aspectMoonTrineArray.push(
            planets[i].aspecting_planet +
              " trines " +
              planets[i].aspected_planet
          );
        }
      }
      if (planets[i].type === "Square") {
        if (planets[i].aspecting_planet === "Moon") {
          aspectMoonSquareArray.push(
            planets[i].aspecting_planet +
              " squares " +
              planets[i].aspected_planet
          );
        }
      }
      if (planets[i].type === "Opposition") {
        if (planets[i].aspecting_planet === "Moon") {
          aspectMoonOpposeArray.push(
            planets[i].aspecting_planet +
              " opposes " +
              planets[i].aspected_planet
          );
        }
      }
      if (planets[i].type === "Conjunction") {
        if (planets[i].aspecting_planet === "Moon") {
          aspectMoonConjunctArray.push(
            planets[i].aspecting_planet +
              " conjuncts " +
              planets[i].aspected_planet
          );
        }
      }
    }

    if (aspectMoonSextileArray.length === 0) {
      aspectMoonSextileArray = this.state.aspectMoonSextileArray;
    }
    if (aspectMoonTrineArray.length === 0) {
      aspectMoonTrineArray = this.state.aspectMoonTrineArray;
    }
    if (aspectMoonSquareArray.length === 0) {
      aspectMoonSquareArray = this.state.aspectMoonSquareArray;
    }
    if (aspectMoonOpposeArray.length === 0) {
      aspectMoonOpposeArray = this.state.aspectMoonOpposeArray;
    }
    if (aspectMoonConjunctArray.length === 0) {
      aspectMoonConjunctArray = this.state.aspectMoonConjunctArray;
    }
    this.setState({
      aspectMoonSextileArray: aspectMoonSextileArray,
      aspectMoonTrineArray: aspectMoonTrineArray,
      aspectMoonSquareArray: aspectMoonSquareArray,
      aspectMoonOpposeArray: aspectMoonOpposeArray,
      aspectMoonConjunctArray: aspectMoonConjunctArray,
    });
  };

  calculateRisingAspects = (planets) => {
    var risingarray = [];
    for (var i = 0; i < planets.length; i++) {
      if (planets[i].house === 1) {
        risingarray.push(planets[i].name);
      }
    }
    if (risingarray.length === 0) {
      risingarray = this.state.risingAspect;
    }
    this.setState({
      risingAspect: risingarray,
    });
  };

  calculateAscendantAspects = (planets) => {
    var aspectAscendantSextileArray = [];
    var aspectAscendantTrineArray = [];
    var aspectAscendantSquareArray = [];
    var aspectAscendantOpposeArray = [];
    var aspectAscendantConjunctArray = [];

    for (var i = 0; i < planets.length; i++) {
      if (planets[i].type === "Sextile") {
        if (planets[i].aspecting_planet === "Ascendant") {
          aspectAscendantSextileArray.push(
            planets[i].aspecting_planet +
              " sextiles " +
              planets[i].aspected_planet
          );
        }
      }
      if (planets[i].type === "Trine") {
        if (planets[i].aspecting_planet === "Ascendant") {
          aspectAscendantTrineArray.push(
            planets[i].aspecting_planet +
              " trines " +
              planets[i].aspected_planet
          );
        }
      }
      if (planets[i].type === "Square") {
        if (planets[i].aspecting_planet === "Ascendant") {
          aspectAscendantSquareArray.push(
            planets[i].aspecting_planet +
              " squares " +
              planets[i].aspected_planet
          );
        }
      }
      if (planets[i].type === "Opposition") {
        if (
          planets[i].aspecting_planet === "Ascendant" &&
          planets[i].aspected_planet !== "DC"
        ) {
          aspectAscendantOpposeArray.push(
            planets[i].aspecting_planet +
              " opposes " +
              planets[i].aspected_planet
          );
        }
      }
      if (planets[i].type === "Conjunction") {
        if (planets[i].aspecting_planet === "Ascendant") {
          aspectAscendantConjunctArray.push(
            planets[i].aspecting_planet +
              " conjuncts " +
              planets[i].aspected_planet
          );
        }
      }
    }

    if (aspectAscendantSextileArray.length === 0) {
      aspectAscendantSextileArray = this.state.aspectAscendantSextileArray;
    }
    if (aspectAscendantTrineArray.length === 0) {
      aspectAscendantTrineArray = this.state.aspectAscendantTrineArray;
    }
    if (aspectAscendantSquareArray.length === 0) {
      aspectAscendantSquareArray = this.state.aspectAscendantSquareArray;
    }
    if (aspectAscendantOpposeArray.length === 0) {
      aspectAscendantOpposeArray = this.state.aspectAscendantOpposeArray;
    }
    if (aspectAscendantConjunctArray.length === 0) {
      aspectAscendantConjunctArray = this.state.aspectAscendantConjunctArray;
    }

    this.setState({
      aspectAscendantSextileArray: aspectAscendantSextileArray,
      aspectAscendantTrineArray: aspectAscendantTrineArray,
      aspectAscendantSquareArray: aspectAscendantSquareArray,
      aspectAscendantOpposeArray: aspectAscendantOpposeArray,
      aspectAscendantConjunctArray: aspectAscendantConjunctArray,
    });
  };

  handleAddressChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    this.setState({
      getTimezone: true,
    });
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({
          address: address,
          lat: latLng.lat,
          lon: latLng.lng,
        });

        //For Google
        // var tmsp = new Date(
        //   this.state.year,
        //   this.state.month - 1,
        //   this.state.day,
        //   adjustedHour,
        //   this.state.min
        // );
        //.getTime() / 1000;
        // console.log(this.state, adjustedHour);

        //For AstroAPI
        var tzDate = `${this.state.month}-${this.state.day}-${this.state.year}`;
        var tzHour = this.state.hour;

        //For Google
        // fetch(
        //   "https://maps.googleapis.com/maps/api/timezone/json?location=" +
        //     latLng.lat +
        //     "," +
        //     latLng.lng +
        //     "&timestamp=" +
        //     tmsp +
        //     "&key=AIzaSyA6Mz4yfOha3kMkYph1C7snq129plcq9pM"
        // )

        //For AstroAPI
        var tzData = {
          latitude: latLng.lat,
          longitude: latLng.lng,
          date: tzDate,
          hour: tzHour
        };
        // console.log(tzData);
        fetch(apiURL, {
          method: 'POST',
          body: JSON.stringify({
            api_name: 'timezone_with_dst',
            data: tzData
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
            var output = response.json();
            return output;
          })
          .then((output) => {
            //console.log(output);
            var calculatedTimezone = output.timezone;

            //Google Calculations
            // var calculatedTimezone;
            // if (this.state.year < 1970) {
            //   calculatedTimezone = output.rawOffset / 3600;
            // } else {
            //   calculatedTimezone = (output.rawOffset + output.dstOffset) / 3600;
            // }

            this.setState({
              tzone: calculatedTimezone,
              getTimezone: false,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => console.error("Error", error));
  };

  handleSubmit = (e) => {
    //console.log(this.state);
    e.preventDefault();

    var displayTime;
    var displayMonth;
    var displayMin = this.state.min;

    if (this.state.month === 1) {
      displayMonth = "January ";
    } else if (this.state.month === 2) {
      displayMonth = "February ";
    } else if (this.state.month === 3) {
      displayMonth = "March ";
    } else if (this.state.month === 4) {
      displayMonth = "April ";
    } else if (this.state.month === 5) {
      displayMonth = "May ";
    } else if (this.state.month === 6) {
      displayMonth = "June ";
    } else if (this.state.month === 7) {
      displayMonth = "July ";
    } else if (this.state.month === 8) {
      displayMonth = "August ";
    } else if (this.state.month === 9) {
      displayMonth = "September ";
    } else if (this.state.month === 10) {
      displayMonth = "October ";
    } else if (this.state.month === 11) {
      displayMonth = "November ";
    } else {
      displayMonth = "December ";
    }

    if (this.state.min < 10) {
      displayMin = "0" + this.state.min;
    }

    if (this.state.hour > 12) {
      displayTime = `${this.state.hour - 12}:${displayMin}PM`;
    } else if (this.state.hour === 0 || this.state.hour === "00") {
      displayTime = `12:${displayMin}AM`;
    } else if (this.state.am_pm === "pm") {
      displayTime = `${this.state.hour}:${displayMin}PM`;
    } else {
      displayTime = `${this.state.hour}:${displayMin}AM`;
    }

    this.setState({
      displayTime: displayTime,
      displayMonth: displayMonth,
    });

    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.address === "" ||
      this.state.day === "" ||
      this.state.month === "" ||
      this.state.year === "" ||
      this.state.min === "" ||
      this.state.hour === "" ||
      this.state.lat === "" ||
      this.state.lon === "" ||
      this.state.tzone === "" ||
      this.state.house_type === ""
    ) {
      alert("Please fill in all fields");

      this.setState({
        loading: false,
      });

      return;
    }

    this.setState({
      loadingChart: true,
      loading: true,
    });

    mixpanel.identify(this.state.email);
    mixpanel.people.set({
      $email: this.state.email,
      "Sign up date": new Date().toISOString(),
      USER_ID: this.state.email,
    });
    mixpanel.track("User Submitted Form");
    mixpanel.track("User Selected: " + this.state.house_type);

    ReactGA.event({
      category: 'Form Choices',
      action: 'House System',
      label: this.state.house_type
    });

    var data = {
      day: this.state.day,
      month: this.state.month,
      year: this.state.year,
      hour: this.state.hour,
      am_pm: this.state.am_pm,
      min: this.state.min,
      lat: this.state.lat,
      lon: this.state.lon,
      tzone: this.state.tzone,
      house_type: this.state.house_type,
    };

    if (data.am_pm === "pm" && data.hour < 12) {
      data.hour = data.hour + 12;
      this.setState({
        hour: data.hour,
      });
    }

    if (data.am_pm === "AM" && data.hour === 12) {
      data.hour = 0;
      this.setState({
        hour: data.hour,
      });
    }

    fetch(apiURL, {
      method: 'POST',
      body: JSON.stringify({
        api_name: 'custom_chart_interpretation',
        data: data
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        var output = response.json();
        return output;
      })
      .then((output) => {
        //console.log(output);
        if (output.moon_phase.moon_phase_name === "New Moon") {
          this.setState({
            moonImage: require("../Images/NewMoon.svg"),
          });
        }
        if (output.moon_phase.moon_phase_name === "Crescent Moon") {
          this.setState({
            moonImage: require("../Images/CrescentMoon.svg"),
          });
        }
        if (output.moon_phase.moon_phase_name === "First Quarter Moon") {
          this.setState({
            moonImage: require("../Images/FirstQuarterMoon.svg"),
          });
        }
        if (output.moon_phase.moon_phase_name === "Gibbous Moon") {
          this.setState({
            moonImage: require("../Images/GibbousMoon.svg"),
          });
        }
        if (output.moon_phase.moon_phase_name === "Full Moon") {
          this.setState({
            moonImage: require("../Images/FullMoon.svg"),
          });
        }
        if (output.moon_phase.moon_phase_name === "Disseminating Moon") {
          this.setState({
            moonImage: require("../Images/DisseminatingMoon.svg"),
          });
        }
        if (output.moon_phase.moon_phase_name === "Last Quarter Moon") {
          this.setState({
            moonImage: require("../Images/LastQuarterMoon.svg"),
          });
        }
        if (output.moon_phase.moon_phase_name === "Balsamic Moon") {
          this.setState({
            moonImage: require("../Images/BalsamicMoon.svg"),
          });
        }

        var aspects = [];
        for (var i = 0; i < output.aspects.length; i++) {
          if (
            output.aspects[i].aspecting_planet !== "Part of Fortune" &&
            output.aspects[i].aspected_planet !== "Part of Fortune"
          ) {
            aspects.push(output.aspects[i]);
          }
        }
        for (var j = 0; j < aspects.length; j++) {
          if (aspects[j].aspecting_planet === "Node") {
            aspects[j].aspecting_planet = "North Node";
          } else if (aspects[j].aspected_planet === "Node") {
            aspects[j].aspected_planet = "North Node";
          }
        }

        var planets = [];
        for (var k = 0; k < output.planets.length; k++) {
          if (output.planets[k].name !== "Part of Fortune") {
            planets.push(output.planets[k]);
          }
        }

        for (var l = 0; l < planets.length; l++) {
          if (planets[l].name === "Node") {
            planets[l].name = "North Node";
          }
        }

        this.calculateSunAspects(aspects);
        this.calculateMoonAspects(aspects);
        this.calculateAscendantAspects(aspects);
        this.calculateRisingAspects(planets);
        this.downloadChart();

        function createPointDescription(string) {
          var array = string.split(" ");
          var finalDescription = "";
          if (array[0] === "DC" || array[0] === "MC" || array[0] === "IC") {
            finalDescription = finalDescription + array[0] + " in ";
          }
          for (let i = 0; i < array.length; i++) {
            if (
              array[i] === "Taurus" ||
              array[i] === "Aries" ||
              array[i] === "Pisces" ||
              array[i] === "Aquarius" ||
              array[i] === "Capricorn" ||
              array[i] === "Sagittarius" ||
              array[i] === "Scorpio" ||
              array[i] === "Libra" ||
              array[i] === "Virgo" ||
              array[i] === "Leo" ||
              array[i] === "Cancer" ||
              array[i] === "Gemini"
            ) {
              finalDescription = finalDescription + array[i] + " in the ";
            }
          }
          for (let i = 0; i < array.length; i++) {
            if (
              array[i] === "1st" ||
              array[i] === "2nd" ||
              array[i] === "3rd" ||
              array[i] === "4th" ||
              array[i] === "5th" ||
              array[i] === "6th" ||
              array[i] === "7th" ||
              array[i] === "8th" ||
              array[i] === "9th" ||
              array[i] === "10th" ||
              array[i] === "11th" ||
              array[i] === "12th"
            ) {
              finalDescription = finalDescription + array[i] + " house";
            }
          }
          return finalDescription;
        }

        var ascendantRulerDignity;
        var ascendant = output.ascendant_ruler.split(" ");
        for (let i = 0; i < ascendant.length; i++) {
          if (
            ascendant[i] === "Moon" ||
            ascendant[i] === "Sun" ||
            ascendant[i] === "Mercury" ||
            ascendant[i] === "Venus" ||
            ascendant[i] === "Mars" ||
            ascendant[i] === "Jupiter" ||
            ascendant[i] === "Saturn" ||
            ascendant[i] === "Uranus" ||
            ascendant[i] === "Neptune" ||
            ascendant[i] === "Pluto"
          ) {
            // console.log(ascendant[i]);
            var planet = ascendant[i];
            for (let i = 0; i < planets.length; i++) {
              if (planets[i].name === planet) {
                ascendantRulerDignity = planets[i];
              }
            }
          }
        }
        //console.log(ascendantRulerDignity);

        var dcTitle = createPointDescription(output.DC);
        var mcTitle = createPointDescription(output.MC);
        var icTitle = createPointDescription(output.IC);

        this.setState({
          aspects: aspects,
          planets: planets,
          DC: output.DC,
          MC: output.MC,
          IC: output.IC,
          dcTitle: dcTitle,
          mcTitle: mcTitle,
          icTitle: icTitle,
          southNode: output.south_node,
          risingSign: output.rising_sign,
          ascendantRuler: output.ascendant_ruler,
          ascendantRulerDignity: ascendantRulerDignity,
          moonPhase: output.moon_phase,
          loadingChart: false,
          loading: false,
          showChart: true,
        });
      })
      .catch((err) => {
        alert("Please check your inputs and try submitting again.");
        this.setState({
          loading: false,
        });
      });

    API.post("chani-natal-chart", "addemail", {
      body: {
        userEmail: this.state.email,
        userName: this.state.name,
      },
    })
      .then((response) => {
        API.post("mailchimp", "mailchimp", {
          body: {
            "email_address": this.state.email,
            "status_if_new": "subscribed",
            "merge_fields": {
              "FNAME": this.state.name,
              "BIRTHDAY": `${this.state.month}/${this.state.day}`
            }
          },
        }).catch((error) => {
          console.log(response);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    var iframeURL = `https://chart.chaninicholas.com/interactive-wheel-chart?+day=${this.state.day}&+month=${this.state.month}&+year=${this.state.year}&+hour=${this.state.hour}&+minute=${this.state.min}&+place=usa&+latitude=${this.state.lat}&+longitude=${this.state.lon}&+timezone=${this.state.tzone}&+house_type=${this.state.house_type}&+window_size=${this.state.window_size}`;
    // var iframeURL = `http://localhost:8000/interactive-wheel-chart?+day=${this.state.day}&+month=${this.state.month}&+year=${this.state.year}&+hour=${this.state.hour}&+minute=${this.state.min}&+place=usa&+latitude=${this.state.lat}&+longitude=${this.state.lon}&+timezone=${this.state.tzone}&+house_type=${this.state.house_type}&+window_size=${this.state.window_size}`;
    return (
      <div>
        <Header />
        <div className="chart-wrapper">
          {this.state.showChart === true ? (
            <div>
              <div className="chart-header">
                <div className="user-info">
                  <ul>
                    <li>Name: {`${this.state.name}`}</li>
                    <li>
                      Birth Date:{" "}
                      {`${this.state.displayMonth} ${this.state.day}, ${this.state.year}`}
                    </li>
                    <li>Birth Place: {`${this.state.address}`}</li>
                    <li>Birth Time: {this.state.displayTime}</li>
                    <li>
                      House Type:{" "}
                      {
                        this.state.house_type === "whole_sign" ? "Whole Sign" :
                        this.state.house_type === "placidus" ? "Placidus" :
                        this.state.house_type === "campanus" ? "Campanus" :
                        this.state.house_type === "horizontal" ? "Horizon" :
                        this.state.house_type === "koch" ? "Koch" :
                        this.state.house_type === "porphyry" ? "Porphyry" :
                        this.state.house_type === "topocentric" ? "Topocentric" :
                        this.state.house_type === "equal" ? "Equal"
                      : "" }
                    </li>
                    <button onClick={this.editBirthInfo}>
                      Edit Birth Info
                    </button>
                    {this.state.sentEmail === false ? (
                      <div>
                        <button onClick={this.chartEmail}>
                          {this.state.loadingEmail === false
                            ? "Email My Chart!"
                            : "Sending Email..."}
                        </button>
                      </div>
                    ) : (
                      <p style={{ marginTop: "10px" }}>
                        Thank you! Please check your email.
                      </p>
                    )}
                  </ul>
                </div>
                {/* <div className="chart-download">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.chartDownload}
                  >
                    <button>Click here to download your chart!</button>
                  </a> */}
                {/* <a href="javascript:window.print()">Print me!</a> 
                </div> */}
              </div>
              <div className="instructional-sentence">
                <p>Click or tap a planet below to see its aspects.</p>
              </div>
              {this.state.loadingChart ? (
                <p className="loading-chart">Loading chart...</p>
              ) : (
                <div className="embed-container">
                  <iframe src={iframeURL} title="chart" />
                </div>
              )}
              <div className="key-wrapper">
                <div className="chart-key">
                  <table className="aspect-key">
                    <tbody>
                      <tr>
                        <th colSpan="2">Aspects</th>
                      </tr>
                      <tr>
                        <td className="sextile-color">Sextile</td>
                      </tr>
                      <tr>
                        <td className="trine-color">Trine</td>
                      </tr>
                      <tr>
                        <td className="square-color">Square</td>
                      </tr>
                      <tr>
                        <td className="opposition-color">Opposition</td>
                      </tr>
                      <tr>
                        <td className="conjunction-color">Conjunction</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="chart-key">
                  <table className="planet-key-mobile">
                    <tbody>
                      <tr>
                        <th colSpan="2">Key</th>
                      </tr>
                      <tr>
                        <td>
                          <img src={require("../Images/Sun.svg")} alt="Sun" />
                        </td>
                        <td>Sun</td>
                      </tr>
                      <tr>
                        <td>
                          <img src={require("../Images/moon.svg")} alt="Moon" />
                        </td>
                        <td>Moon</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src={require("../Images/ascendant.svg")}
                            alt="Ascendant"
                          />
                        </td>
                        <td>Ascendant</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src={require("../Images/Mercury.svg")}
                            alt="Mercury"
                          />
                        </td>
                        <td>Mercury</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src={require("../Images/Venus.svg")}
                            alt="Venus"
                          />
                        </td>
                        <td>Venus</td>
                      </tr>
                      <tr>
                        <td>
                          <img src={require("../Images/Mars.svg")} alt="Mars" />
                        </td>
                        <td>Mars</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src={require("../Images/Jupiter.svg")}
                            alt="Jupiter"
                          />
                        </td>
                        <td>Jupiter</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src={require("../Images/Saturn.svg")}
                            alt="Saturn"
                          />
                        </td>
                        <td>Saturn</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src={require("../Images/Uranus.svg")}
                            alt="Uranus"
                          />
                        </td>
                        <td>Uranus</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src={require("../Images/Neptune.svg")}
                            alt="Neptune"
                          />
                        </td>
                        <td>Neptune</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src={require("../Images/Pluto.svg")}
                            alt="Pluto"
                          />
                        </td>
                        <td>Pluto</td>
                      </tr>

                      <tr>
                        <td>
                          <img
                            src={require("../Images/chiron.svg")}
                            alt="Chiron"
                          />
                        </td>
                        <td>Chiron</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src={require("../Images/northnode.svg")}
                            alt="North Node"
                          />
                        </td>
                        <td>North Node</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src={require("../Images/southnode.svg")}
                            alt="North Node"
                          />
                        </td>
                        <td>South Node</td>
                      </tr>
                      <tr>
                        <td>
                          <img src={require("../Images/dc.png")} alt="DC" />
                        </td>
                        <td>Descendant</td>
                      </tr>
                      <tr>
                        <td>
                          <img src={require("../Images/mc.png")} alt="MC" />
                        </td>
                        <td>Midheaven</td>
                      </tr>
                      <tr>
                        <td>
                          <img src={require("../Images/ic.png")} alt="IC" />
                        </td>
                        <td>Imum Coeli</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="planet-key">
                    <tbody>
                      <tr>
                        <th colSpan="17">Key</th>
                      </tr>
                      <tr>
                        <td>
                          <img src={require("../Images/Sun.svg")} alt="Sun" />
                        </td>
                        <td>
                          <img src={require("../Images/moon.svg")} alt="Moon" />
                        </td>
                        <td>
                          <img
                            src={require("../Images/ascendant.svg")}
                            alt="Ascendant"
                          />
                        </td>
                        <td>
                          <img
                            src={require("../Images/Mercury.svg")}
                            alt="Mercury"
                          />
                        </td>
                        <td>
                          <img
                            src={require("../Images/Venus.svg")}
                            alt="Venus"
                          />
                        </td>
                        <td>
                          <img src={require("../Images/Mars.svg")} alt="Mars" />
                        </td>
                        <td>
                          <img
                            src={require("../Images/Jupiter.svg")}
                            alt="Jupiter"
                          />
                        </td>
                        <td>
                          <img
                            src={require("../Images/Saturn.svg")}
                            alt="Saturn"
                          />
                        </td>
                        <td>
                          <img
                            src={require("../Images/Uranus.svg")}
                            alt="Uranus"
                          />
                        </td>

                        <td>
                          <img
                            src={require("../Images/Neptune.svg")}
                            alt="Neptune"
                          />
                        </td>
                        <td>
                          <img
                            src={require("../Images/Pluto.svg")}
                            alt="Pluto"
                          />
                        </td>

                        <td>
                          <img
                            src={require("../Images/chiron.svg")}
                            alt="Chiron"
                          />
                        </td>
                        <td>
                          <img
                            src={require("../Images/northnode.svg")}
                            alt="North Node"
                          />
                        </td>
                        <td>
                          <img
                            src={require("../Images/southnode.svg")}
                            alt="South Node"
                          />
                        </td>
                        <td>
                          <img src={require("../Images/dc.png")} alt="DC" />
                        </td>
                        <td>
                          <img src={require("../Images/mc.png")} alt="MC" />
                        </td>
                        <td>
                          <img src={require("../Images/ic.png")} alt="IC" />
                        </td>
                      </tr>
                      <tr>
                        <td>Sun</td>
                        <td>Moon</td>
                        <td>Ascendant</td>
                        <td>Mercury</td>
                        <td>Venus</td>
                        <td>Mars</td>
                        <td>Jupiter</td>
                        <td>Saturn</td>
                        <td>Uranus</td>
                        <td>Neptune</td>
                        <td>Pluto</td>
                        <td>Chiron</td>
                        <td>North Node</td>
                        <td>South Node</td>
                        <td>Descendant</td>
                        <td>Midheaven</td>
                        <td>Imum Coeli</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : null}
          {this.state.planets !== "" ? (
            <div>
              <p className="section-wrapper">
                <strong>
                  The keys to your chart + outline of aspects below serve as a
                  companion to my book,{" "}
                  <a
                    href="https://chani-nicholas.myshopify.com/products/you-were-born-for-this-astrology-for-radical-self-acceptance"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    YOU WERE BORN FOR THIS: Astrology for Radical
                    Self-Acceptance
                  </a>
                  . You can buy my book on{" "}
                  <a
                    href="https://chani-nicholas.myshopify.com/products/you-were-born-for-this-astrology-for-radical-self-acceptance"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    my site
                  </a>
                  , as well as on{" "}
                  <a
                    href="https://www.amazon.com/You-Were-Born-This-Self-Acceptance/dp/0062840630/ref=as_li_ss_tl?crid=2KTJYZ1YCR2G4&keywords=chani+nicholas&qid=1572980835&sprefix=chani+,aps,206&sr=8-1&linkCode=sl1&tag=chaninicholas-20&linkId=93960e66470397ac2ac7d993129b679d&language=en_US"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Amazon
                  </a>
                  ,{" "}
                  <a
                    href="https://www.barnesandnoble.com/w/you-were-born-for-this-chani-nicholas/1131085841;jsessionid=E8B90F6A867EADB8BEA66983E4A02635.prodny_store02-atgap15?ean=9780062840639&st=AFF&2sid=HarperCollins%20Publishers%20LLC_7252169_NA&sourceId=AFFHarperCollins%20Publishers%20LLC#/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Barnes & Noble
                  </a>
                  ,{" "}
                  <a
                    href="https://www.indiebound.org/book/9780062840639"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Indiebound
                  </a>
                  , or via{" "}
                  <a
                    href="https://books.apple.com/us/book/you-were-born-for-this/id1265788192?ign-mpt=uo%3D4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apple Books
                  </a>{" "}
                  or{" "}
                  <a
                    href="https://play.google.com/store/books/details/Chani_Nicholas_You_Were_Born_for_This?id=1PpRDwAAQBAJ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Google Books
                  </a>
                  . To build an altar for the keys to your chart, please see
                  here for{" "}
                  <a
                    href="https://chaninicholas.com/altar-suggestions/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    a list of altar suggestions for each sign
                  </a>
                  .
                </strong>
              </p>
              <p>
                <strong>The Keys to Your Chart</strong>
              </p>
              {/* Planet Chart */}
              <table className="key-chart">
                <tbody>
                  <tr>
                    <td className="icon-container">
                      <img src={require("../Images/Sun.svg")} alt="Sun Icon" />
                    </td>
                    <td>
                      <p>
                        {this.state.planets[0].interpretation
                          .split(" ")
                          .map((word, id) => {
                            if (
                              word === "Sun" ||
                              word === "Taurus" ||
                              word === "Aries" ||
                              word === "Pisces" ||
                              word === "Aquarius" ||
                              word === "Capricorn" ||
                              word === "Sagittarius" ||
                              word === "Scorpio" ||
                              word === "Libra" ||
                              word === "Virgo" ||
                              word === "Leo" ||
                              word === "Cancer" ||
                              word === "Gemini" ||
                              word === "house" ||
                              word === "1st" ||
                              word === "2nd" ||
                              word === "3rd" ||
                              word === "11th" ||
                              word === "12th" ||
                              word === "4th" ||
                              word === "5th" ||
                              word === "6th" ||
                              word === "7th" ||
                              word === "8th" ||
                              word === "9th" ||
                              word === "10th"
                            ) {
                              return <strong key={id}>{word} </strong>;
                            } else {
                              return <span key={id}>{word} </span>;
                            }
                          })}
                      </p>
                      {this.state.planets[0].special.map((special, id) => {
                        if (special === "is in its joy.") {
                          return (
                            <p key={id}>
                              {`Your Sun is in its joy in the ${
                                this.state.planets[0].house
                              }${
                                this.state.planets[0].house === 1 ? "st" : ""
                              }${
                                this.state.planets[0].house === 2 ? "nd" : ""
                              }${
                                this.state.planets[0].house === 3 ? "rd" : ""
                              }${
                                this.state.planets[0].house > 3 ? "th" : ""
                              } house.`}
                            </p>
                          );
                        } else {
                          return (
                            <p key={id}>
                              {`Your Sun in ${
                                this.state.planets[0].sign
                              } ${special.toLowerCase()}`}
                            </p>
                          );
                        }
                      })}
                      <p>
                        <a
                          href="https://chani-nicholas.myshopify.com/products/you-were-born-for-this-astrology-for-radical-self-acceptance"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Read chapter 3
                        </a>{" "}
                        to learn more about your Sun.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="icon-container">
                      <img
                        src={require("../Images/moon.svg")}
                        alt="Moon Icon"
                      />
                    </td>
                    <td>
                      <p>
                        {this.state.planets[1].interpretation
                          .split(" ")
                          .map((word, id) => {
                            if (
                              word === "Moon" ||
                              word === "Taurus" ||
                              word === "Aries" ||
                              word === "Pisces" ||
                              word === "Aquarius" ||
                              word === "Capricorn" ||
                              word === "Sagittarius" ||
                              word === "Scorpio" ||
                              word === "Libra" ||
                              word === "Virgo" ||
                              word === "Leo" ||
                              word === "Cancer" ||
                              word === "Gemini" ||
                              word === "house" ||
                              word === "1st" ||
                              word === "2nd" ||
                              word === "3rd" ||
                              word === "11th" ||
                              word === "12th" ||
                              word === "4th" ||
                              word === "5th" ||
                              word === "6th" ||
                              word === "7th" ||
                              word === "8th" ||
                              word === "9th" ||
                              word === "10th"
                            ) {
                              return <strong key={id}>{word} </strong>;
                            } else {
                              return <span key={id}>{word} </span>;
                            }
                          })}
                      </p>
                      {this.state.planets[1].special.map((special, id) => {
                        if (special === "is in its joy.") {
                          return (
                            <p key={id}>
                              {`Your Moon is in its joy in the ${
                                this.state.planets[1].house
                              }${
                                this.state.planets[1].house === 1 ? "st" : ""
                              }${
                                this.state.planets[1].house === 2 ? "nd" : ""
                              }${
                                this.state.planets[1].house === 3 ? "rd" : ""
                              }${
                                this.state.planets[1].house > 3 ? "th" : ""
                              } house.`}
                            </p>
                          );
                        } else {
                          return (
                            <p key={id}>
                              {`Your Moon in ${
                                this.state.planets[1].sign
                              } ${special.toLowerCase()}`}
                            </p>
                          );
                        }
                      })}
                      <p>
                        {" "}
                        <a
                          href="https://chani-nicholas.myshopify.com/products/you-were-born-for-this-astrology-for-radical-self-acceptance"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Read chapter 4
                        </a>{" "}
                        to learn more about your Moon.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="icon-container">
                      <img
                        className="icon-container"
                        src={require("../Images/ascendant.svg")}
                        alt="Ascendant Icon"
                      />
                    </td>
                    <td>
                      <p>
                        {this.state.risingSign.split(" ").map((word, id) => {
                          if (word === "in") {
                            return <span></span>;
                          } else {
                            if (
                              word === "Rising" ||
                              word === "Taurus" ||
                              word === "Aries" ||
                              word === "Pisces" ||
                              word === "Aquarius" ||
                              word === "Capricorn" ||
                              word === "Sagittarius" ||
                              word === "Scorpio" ||
                              word === "Libra" ||
                              word === "Virgo" ||
                              word === "Leo" ||
                              word === "Cancer" ||
                              word === "Gemini" ||
                              word === "Sign"
                            ) {
                              return <strong key={id}>{word} </strong>;
                            } else {
                              return <span key={id}>{word} </span>;
                            }
                          }
                        })}
                      </p>
                      <p>
                        <a
                          href="https://chani-nicholas.myshopify.com/products/you-were-born-for-this-astrology-for-radical-self-acceptance"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Read chapter 5
                        </a>{" "}
                        to learn more about your rising sign.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="icon-container">
                      <img
                        src={require("../Images/ascendant-ruler.svg")}
                        alt="Ascendant Icon"
                      />
                    </td>
                    <td>
                      <p>
                        {this.state.ascendantRuler
                          .split(" ")
                          .map((word, id) => {
                            if (
                              word === "Ascendant" ||
                              word === "Ruler" ||
                              word === "Moon" ||
                              word === "Sun" ||
                              word === "Mercury" ||
                              word === "Venus" ||
                              word === "Mars" ||
                              word === "Jupiter" ||
                              word === "Saturn" ||
                              word === "Uranus" ||
                              word === "Neptune" ||
                              word === "Pluto" ||
                              word === "Taurus" ||
                              word === "Aries" ||
                              word === "Pisces" ||
                              word === "Aquarius" ||
                              word === "Capricorn" ||
                              word === "Sagittarius" ||
                              word === "Scorpio" ||
                              word === "Libra" ||
                              word === "Virgo" ||
                              word === "Leo" ||
                              word === "Cancer" ||
                              word === "Gemini" ||
                              word === "house" ||
                              word === "1st" ||
                              word === "2nd" ||
                              word === "3rd" ||
                              word === "11th" ||
                              word === "12th" ||
                              word === "4th" ||
                              word === "5th" ||
                              word === "6th" ||
                              word === "7th" ||
                              word === "8th" ||
                              word === "9th" ||
                              word === "10th"
                            ) {
                              return <strong key={id}>{word} </strong>;
                            } else {
                              return <span key={id}>{word} </span>;
                            }
                          })}
                      </p>
                      {this.state.ascendantRulerDignity.special.map(
                        (special, id) => {
                          if (special === "is in its joy.") {
                            return (
                              <p key={id}>
                                {`${
                                  this.state.ascendantRulerDignity.name
                                } is in its joy in the ${
                                  this.state.ascendantRulerDignity.house
                                }${
                                  this.state.ascendantRulerDignity.house === 1
                                    ? "st"
                                    : ""
                                }${
                                  this.state.ascendantRulerDignity.house === 2
                                    ? "nd"
                                    : ""
                                }${
                                  this.state.ascendantRulerDignity.house === 3
                                    ? "rd"
                                    : ""
                                }${
                                  this.state.ascendantRulerDignity.house > 3
                                    ? "th"
                                    : ""
                                } house.`}
                              </p>
                            );
                          } else {
                            return (
                              <p key={id}>
                                {`${this.state.ascendantRulerDignity.name} in ${
                                  this.state.ascendantRulerDignity.sign
                                } ${special.toLowerCase()}`}
                              </p>
                            );
                          }
                        }
                      )}
                      <p>
                        <a
                          href="https://chani-nicholas.myshopify.com/products/you-were-born-for-this-astrology-for-radical-self-acceptance"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Read chapter 5
                        </a>{" "}
                        to learn more about your ascendant ruler.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* Planetary Text */}
              <div className="section-top-wrapper">
                <p>
                  <strong>Planetary Aspects</strong>
                </p>
                <p>
                  Aspects are the relationships that two or more planets or
                  points have with one another. Just like all relationships,
                  some are easy and uplifting, while others can present
                  challenges and be discouraging. We can place aspects into
                  three categories: gifts, challenges, and mergers. Learn more
                  about what each aspect means in{" "}
                  <a
                    href="https://chani-nicholas.myshopify.com/products/you-were-born-for-this-astrology-for-radical-self-acceptance"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    chapter 3
                  </a>{" "}
                  for the Sun,{" "}
                  <a
                    href="https://chani-nicholas.myshopify.com/products/you-were-born-for-this-astrology-for-radical-self-acceptance"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    chapter 4
                  </a>{" "}
                  for the Moon, and{" "}
                  <a
                    href="https://chani-nicholas.myshopify.com/products/you-were-born-for-this-astrology-for-radical-self-acceptance"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    chapter 5
                  </a>{" "}
                  for the Rising Sign.
                </p>
              </div>
              {/* Sun Chart */}
              <div>
                <p>
                  <strong>SUN</strong>
                </p>
                <table className="key-chart">
                  <tbody>
                    <tr>
                      <td>Gifts</td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li>Sextile</li>
                          <ul>
                            {this.state.aspectSextileArray.map((item, id) => {
                              return <li key={id}> {item}</li>;
                            })}
                          </ul>
                          <li>Trines</li>
                          <ul>
                            {this.state.aspectTrineArray.map((item, id) => {
                              return <li key={id}> {item}</li>;
                            })}
                          </ul>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Challenges</td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li>Square</li>
                          <ul>
                            {this.state.aspectSquareArray.map((item, id) => {
                              return <li key={id}> {item}</li>;
                            })}
                          </ul>
                          <li>Opposition</li>
                          <ul>
                            {this.state.aspectOpposeArray.map((item, id) => {
                              return <li key={id}> {item}</li>;
                            })}
                          </ul>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Mergers</td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li>Conjunction</li>
                          <ul>
                            {this.state.aspectConjunctArray.map((item, id) => {
                              return <li key={id}> {item}</li>;
                            })}
                          </ul>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Moon Chart */}
              <div className="section-wrapper">
                <p>
                  <strong>MOON</strong>
                </p>
                <table className="key-chart">
                  <tbody>
                    <tr>
                      <td>Gifts</td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li>Sextile</li>
                          <ul>
                            {this.state.aspectMoonSextileArray.map(
                              (item, id) => {
                                return <li key={id}> {item}</li>;
                              }
                            )}
                          </ul>
                          <li>Trines</li>
                          <ul>
                            {this.state.aspectMoonTrineArray.map((item, id) => {
                              return <li key={id}> {item}</li>;
                            })}{" "}
                          </ul>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Challenges</td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li>Square</li>
                          <ul>
                            {this.state.aspectMoonSquareArray.map(
                              (item, id) => {
                                return <li key={id}> {item}</li>;
                              }
                            )}
                          </ul>
                          <li>Opposition</li>
                          <ul>
                            {this.state.aspectMoonOpposeArray.map(
                              (item, id) => {
                                return <li key={id}> {item}</li>;
                              }
                            )}
                          </ul>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Mergers</td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li>Conjunction</li>
                          <ul>
                            {this.state.aspectMoonConjunctArray.map(
                              (item, id) => {
                                return <li key={id}> {item}</li>;
                              }
                            )}
                          </ul>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Ascendant Chart */}
              <div className="section-wrapper">
                <p>
                  <strong>ASCENDANT</strong>
                </p>
                <table className="key-chart">
                  <tbody>
                    <tr>
                      <td>Planets in Your First House</td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          {this.state.risingAspect.map((item, id) => {
                            return <li key={id}> {item}</li>;
                          })}
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Gifts</td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li>Sextile</li>
                          <ul>
                            {this.state.aspectAscendantSextileArray.map(
                              (item, id) => {
                                return <li key={id}> {item}</li>;
                              }
                            )}
                          </ul>
                          <li>Trines</li>
                          <ul>
                            {this.state.aspectAscendantTrineArray.map(
                              (item, id) => {
                                return <li key={id}> {item}</li>;
                              }
                            )}{" "}
                          </ul>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Challenges</td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li>Square</li>
                          <ul>
                            {this.state.aspectAscendantSquareArray.map(
                              (item, id) => {
                                return <li key={id}> {item}</li>;
                              }
                            )}
                          </ul>
                          <li>Opposition</li>
                          <ul>
                            {this.state.aspectAscendantOpposeArray.map(
                              (item, id) => {
                                return <li key={id}> {item}</li>;
                              }
                            )}
                          </ul>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Mergers</td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li>Conjunction</li>
                          <ul>
                            {this.state.aspectAscendantConjunctArray.map(
                              (item, id) => {
                                return <li key={id}> {item}</li>;
                              }
                            )}
                          </ul>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Natal Moon Phases */}

              <div className="section-wrapper">
                <p>
                  <strong>Your Natal Moon Phase</strong>
                </p>
                <table className="key-chart">
                  <tbody>
                    <tr className="moon-phase-mobile">
                      <td className="icon-container">
                        <img src={this.state.moonImage} alt="Moon Icon" />
                      </td>
                      <td className="moon-info">
                        Your were born under a{" "}
                        <strong>{this.state.moonPhase.moon_phase_name}</strong>
                      </td>
                      <td>{this.state.moonPhase.moon_phase_description} </td>
                    </tr>
                  </tbody>
                </table>
                <p style={{ marginTop: "5px" }}>
                  For more information on your moon phase, check out the
                  workshop{" "}
                  <a
                    href="https://chani-nicholas.myshopify.com/products/your-moon-understanding-the-moon-in-your-chart"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Your Moon
                  </a>
                  .
                </p>
              </div>

              {/* All planetary placements */}
              <div className="section-wrapper">
                <p>
                  <strong>All Planetary Placements</strong>
                </p>
                <p>
                  In astrology, the planets are described as the “who” of our
                  charts. Each planet in your chart has to function in the style
                  of the sign it is in - signs are the “how.” And houses are the
                  places in our chart where the planets are located - houses are
                  the “where.” If the planets are the actors and the signs are
                  their costumes, then the houses are the sets where their
                  stories are lived out. Each traditional planet has two signs
                  in which it feels at home, two signs in which it feels
                  uncomfortable, and one sign where it feels disrespected. For
                  more on conditions, read page 25 of{" "}
                  <a
                    href="https://chani-nicholas.myshopify.com/products/you-were-born-for-this-astrology-for-radical-self-acceptance"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    You Were Born For This
                  </a>
                  .{" "}
                </p>
                {/* MOBILE PLANETS */}
                <div className="panel-group">
                  <PanelGroup
                    accordion
                    id="mercury"
                    className="accordion-planets"
                  >
                    <Panel eventKey="1">
                      <Panel.Heading>
                        <Panel.Title toggle>
                          <img
                            src={require("../Images/Mercury.svg")}
                            alt="Mercury Icon"
                          />{" "}
                          <strong>Mercury</strong> in{" "}
                          <strong>{this.state.planets[3].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[3].house}
                            {this.state.planets[3].house === 1 ? "st" : null}
                            {this.state.planets[3].house === 2 ? "nd" : null}
                            {this.state.planets[3].house === 3 ? "rd" : null}
                            {this.state.planets[3].house > 3 ? "th" : null}{" "}
                            house
                          </strong>
                          .
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body collapsible>
                        <p>{this.state.planets[3].interpretation}</p>
                        {this.state.planets[3].special.map((special, id) => {
                          if (special === "is in its joy.") {
                            return (
                              <p key={id}>
                                {`Mercury is in its joy in the ${
                                  this.state.planets[3].house
                                }${
                                  this.state.planets[3].house === 1 ? "st" : ""
                                }${
                                  this.state.planets[3].house === 2 ? "nd" : ""
                                }${
                                  this.state.planets[3].house === 3 ? "rd" : ""
                                }${
                                  this.state.planets[3].house > 3 ? "th" : ""
                                } house.`}
                              </p>
                            );
                          } else {
                            return (
                              <p key={id}>
                                {`Mercury in ${
                                  this.state.planets[3].sign
                                } ${special.toLowerCase()}`}
                              </p>
                            );
                          }
                        })}
                        {this.state.planets[3].is_retro === "true" ? (
                          <p>You were born when Mercury was retrograde.</p>
                        ) : null}
                      </Panel.Body>
                    </Panel>
                  </PanelGroup>
                  <PanelGroup
                    accordion
                    id="venus"
                    className="accordion-planets"
                  >
                    <Panel eventKey="1">
                      <Panel.Heading>
                        <Panel.Title toggle>
                          <img
                            src={require("../Images/Venus.svg")}
                            alt="Venus Icon"
                          />{" "}
                          <strong>Venus</strong> in{" "}
                          <strong>{this.state.planets[5].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[5].house}
                            {this.state.planets[5].house === 1 ? "st" : null}
                            {this.state.planets[5].house === 2 ? "nd" : null}
                            {this.state.planets[5].house === 3 ? "rd" : null}
                            {this.state.planets[5].house > 3 ? "th" : null}{" "}
                            house
                          </strong>
                          .
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body collapsible>
                        <p>{this.state.planets[5].interpretation}</p>
                        {this.state.planets[5].special.map((special, id) => {
                          if (special === "is in its joy.") {
                            return (
                              <p key={id}>
                                {`Venus is in its joy in the ${
                                  this.state.planets[5].house
                                }${
                                  this.state.planets[5].house === 1 ? "st" : ""
                                }${
                                  this.state.planets[5].house === 2 ? "nd" : ""
                                }${
                                  this.state.planets[5].house === 3 ? "rd" : ""
                                }${
                                  this.state.planets[5].house > 3 ? "th" : ""
                                } house.`}
                              </p>
                            );
                          } else {
                            return (
                              <p key={id}>
                                {`Venus in ${
                                  this.state.planets[5].sign
                                } ${special.toLowerCase()}`}
                              </p>
                            );
                          }
                        })}
                        {this.state.planets[5].is_retro === "true" ? (
                          <p>You were born when Venus was retrograde.</p>
                        ) : null}
                      </Panel.Body>
                    </Panel>
                  </PanelGroup>
                  <PanelGroup accordion id="mars" className="accordion-planets">
                    <Panel eventKey="1">
                      <Panel.Heading>
                        <Panel.Title toggle>
                          <img
                            src={require("../Images/Mars.svg")}
                            alt="Mars Icon"
                          />{" "}
                          <strong>Mars</strong> in{" "}
                          <strong>{this.state.planets[2].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[2].house}
                            {this.state.planets[2].house === 1 ? "st" : null}
                            {this.state.planets[2].house === 2 ? "nd" : null}
                            {this.state.planets[2].house === 3 ? "rd" : null}
                            {this.state.planets[2].house > 3 ? "th" : null}{" "}
                            house
                          </strong>
                          .
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body collapsible>
                        <p>{this.state.planets[2].interpretation}</p>
                        {this.state.planets[2].special.map((special, id) => {
                          if (special === "is in its joy.") {
                            return (
                              <p key={id}>
                                {`Mars is in its joy in the ${
                                  this.state.planets[2].house
                                }${
                                  this.state.planets[2].house === 1 ? "st" : ""
                                }${
                                  this.state.planets[2].house === 2 ? "nd" : ""
                                }${
                                  this.state.planets[2].house === 3 ? "rd" : ""
                                }${
                                  this.state.planets[2].house > 3 ? "th" : ""
                                } house.`}
                              </p>
                            );
                          } else {
                            return (
                              <p key={id}>
                                {`Mars in ${
                                  this.state.planets[2].sign
                                } ${special.toLowerCase()}`}
                              </p>
                            );
                          }
                        })}
                        {this.state.planets[2].is_retro === "true" ? (
                          <p>You were born when Mars was retrograde.</p>
                        ) : null}
                      </Panel.Body>
                    </Panel>
                  </PanelGroup>
                  <PanelGroup
                    accordion
                    id="jupiter"
                    className="accordion-planets"
                  >
                    <Panel eventKey="1">
                      <Panel.Heading>
                        <Panel.Title toggle>
                          <img
                            src={require("../Images/Jupiter.svg")}
                            alt="Jupiter Icon"
                          />{" "}
                          <strong>Jupiter</strong> in{" "}
                          <strong>{this.state.planets[4].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[4].house}
                            {this.state.planets[4].house === 1 ? "st" : null}
                            {this.state.planets[4].house === 2 ? "nd" : null}
                            {this.state.planets[4].house === 3 ? "rd" : null}
                            {this.state.planets[4].house > 3 ? "th" : null}{" "}
                            house
                          </strong>
                          .
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body collapsible>
                        <p>{this.state.planets[4].interpretation}</p>
                        {this.state.planets[4].special.map((special, id) => {
                          if (special === "is in its joy.") {
                            return (
                              <p key={id}>
                                {`Jupiter is in its joy in the ${
                                  this.state.planets[4].house
                                }${
                                  this.state.planets[4].house === 1 ? "st" : ""
                                }${
                                  this.state.planets[4].house === 2 ? "nd" : ""
                                }${
                                  this.state.planets[4].house === 3 ? "rd" : ""
                                }${
                                  this.state.planets[4].house > 3 ? "th" : ""
                                } house.`}
                              </p>
                            );
                          } else {
                            return (
                              <p key={id}>
                                {`Jupiter in ${
                                  this.state.planets[4].sign
                                } ${special.toLowerCase()}`}
                              </p>
                            );
                          }
                        })}
                        {this.state.planets[4].is_retro === "true" ? (
                          <p>You were born when Jupiter was retrograde.</p>
                        ) : null}
                      </Panel.Body>
                    </Panel>
                  </PanelGroup>
                  <PanelGroup
                    accordion
                    id="saturn"
                    className="accordion-planets"
                  >
                    <Panel eventKey="1">
                      <Panel.Heading>
                        <Panel.Title toggle>
                          <img
                            src={require("../Images/Saturn.svg")}
                            alt="Saturn Icon"
                          />{" "}
                          <strong>Saturn</strong> in{" "}
                          <strong>{this.state.planets[6].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[6].house}
                            {this.state.planets[6].house === 1 ? "st" : null}
                            {this.state.planets[6].house === 2 ? "nd" : null}
                            {this.state.planets[6].house === 3 ? "rd" : null}
                            {this.state.planets[6].house > 3 ? "th" : null}{" "}
                            house
                          </strong>
                          .
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body collapsible>
                        <p>{this.state.planets[6].interpretation}</p>
                        {this.state.planets[6].special.map((special, id) => {
                          if (special === "is in its joy.") {
                            return (
                              <p key={id}>
                                {`Saturn is in its joy in the ${
                                  this.state.planets[6].house
                                }${
                                  this.state.planets[6].house === 1 ? "st" : ""
                                }${
                                  this.state.planets[6].house === 2 ? "nd" : ""
                                }${
                                  this.state.planets[6].house === 3 ? "rd" : ""
                                }${
                                  this.state.planets[6].house > 3 ? "th" : ""
                                } house.`}
                              </p>
                            );
                          } else {
                            return (
                              <p key={id}>
                                {`Saturn in ${
                                  this.state.planets[6].sign
                                } ${special.toLowerCase()}`}
                              </p>
                            );
                          }
                        })}
                        {this.state.planets[6].is_retro === "true" ? (
                          <p>You were born when Saturn was retrograde.</p>
                        ) : null}
                      </Panel.Body>
                    </Panel>
                  </PanelGroup>
                  <PanelGroup
                    accordion
                    id="uranus"
                    className="accordion-planets"
                  >
                    <Panel eventKey="1">
                      <Panel.Heading>
                        <Panel.Title toggle>
                          <img
                            src={require("../Images/Uranus.svg")}
                            alt="Uranus Icon"
                          />{" "}
                          <strong>Uranus</strong> in{" "}
                          <strong>{this.state.planets[7].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[7].house}
                            {this.state.planets[7].house === 1 ? "st" : null}
                            {this.state.planets[7].house === 2 ? "nd" : null}
                            {this.state.planets[7].house === 3 ? "rd" : null}
                            {this.state.planets[7].house > 3 ? "th" : null}{" "}
                            house
                          </strong>
                          .
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body collapsible>
                        <p>{this.state.planets[7].interpretation}</p>
                        {this.state.planets[7].special.map((special, id) => {
                          if (special === "is in its joy.") {
                            return (
                              <p key={id}>
                                {`Uranus is in its joy in the ${
                                  this.state.planets[7].house
                                }${
                                  this.state.planets[7].house === 1 ? "st" : ""
                                }${
                                  this.state.planets[7].house === 2 ? "nd" : ""
                                }${
                                  this.state.planets[7].house === 3 ? "rd" : ""
                                }${
                                  this.state.planets[7].house > 3 ? "th" : ""
                                } house.`}
                              </p>
                            );
                          } else {
                            return (
                              <p key={id}>
                                {`Uranus in ${
                                  this.state.planets[7].sign
                                } ${special.toLowerCase()}`}
                              </p>
                            );
                          }
                        })}
                        {this.state.planets[7].is_retro === "true" ? (
                          <p>You were born when Uranus was retrograde.</p>
                        ) : null}
                      </Panel.Body>
                    </Panel>
                  </PanelGroup>
                  <PanelGroup
                    accordion
                    id="neptune"
                    className="accordion-planets"
                  >
                    <Panel eventKey="1">
                      <Panel.Heading>
                        <Panel.Title toggle>
                          <img
                            src={require("../Images/Neptune.svg")}
                            alt="Neptune Icon"
                          />{" "}
                          <strong>Neptune</strong> in{" "}
                          <strong>{this.state.planets[8].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[8].house}
                            {this.state.planets[8].house === 1 ? "st" : null}
                            {this.state.planets[8].house === 2 ? "nd" : null}
                            {this.state.planets[8].house === 3 ? "rd" : null}
                            {this.state.planets[8].house > 3 ? "th" : null}{" "}
                            house
                          </strong>
                          .
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body collapsible>
                        <p>{this.state.planets[8].interpretation}</p>
                        {this.state.planets[8].special.map((special, id) => {
                          if (special === "is in its joy.") {
                            return (
                              <p key={id}>
                                {`Neptune is in its joy in the ${
                                  this.state.planets[8].house
                                }${
                                  this.state.planets[8].house === 1 ? "st" : ""
                                }${
                                  this.state.planets[8].house === 2 ? "nd" : ""
                                }${
                                  this.state.planets[8].house === 3 ? "rd" : ""
                                }${
                                  this.state.planets[8].house > 3 ? "th" : ""
                                } house.`}
                              </p>
                            );
                          } else {
                            return (
                              <p key={id}>
                                {`Neptune in ${
                                  this.state.planets[8].sign
                                } ${special.toLowerCase()}`}
                              </p>
                            );
                          }
                        })}
                        {this.state.planets[8].is_retro === "true" ? (
                          <p>You were born when Neptune was retrograde.</p>
                        ) : null}
                      </Panel.Body>
                    </Panel>
                  </PanelGroup>
                  <PanelGroup
                    accordion
                    id="pluto"
                    className="accordion-planets"
                  >
                    <Panel eventKey="1">
                      <Panel.Heading>
                        <Panel.Title toggle>
                          <img
                            src={require("../Images/Pluto.svg")}
                            alt="Pluto Icon"
                          />{" "}
                          <strong>Pluto</strong> in{" "}
                          <strong>{this.state.planets[9].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[9].house}
                            {this.state.planets[9].house === 1 ? "st" : null}
                            {this.state.planets[9].house === 2 ? "nd" : null}
                            {this.state.planets[9].house === 3 ? "rd" : null}
                            {this.state.planets[9].house > 3 ? "th" : null}{" "}
                            house
                          </strong>
                          .
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body collapsible>
                        <p>{this.state.planets[9].interpretation}</p>
                        {this.state.planets[9].special.map((special, id) => {
                          if (special === "is in its joy.") {
                            return (
                              <p key={id}>
                                {`Pluto is in its joy in the ${
                                  this.state.planets[9].house
                                }${
                                  this.state.planets[9].house === 1 ? "st" : ""
                                }${
                                  this.state.planets[9].house === 2 ? "nd" : ""
                                }${
                                  this.state.planets[9].house === 3 ? "rd" : ""
                                }${
                                  this.state.planets[9].house > 3 ? "th" : ""
                                } house.`}
                              </p>
                            );
                          } else {
                            return (
                              <p key={id}>
                                {`Pluto in ${
                                  this.state.planets[9].sign
                                } ${special.toLowerCase()}`}
                              </p>
                            );
                          }
                        })}
                        {this.state.planets[9].is_retro === "true" ? (
                          <p>You were born when Pluto was retrograde.</p>
                        ) : null}
                      </Panel.Body>
                    </Panel>
                  </PanelGroup>
                  <PanelGroup
                    accordion
                    id="chiron"
                    className="accordion-planets"
                  >
                    <Panel eventKey="1">
                      <Panel.Heading>
                        <Panel.Title toggle>
                          <img
                            src={require("../Images/chiron.svg")}
                            alt="Chiron Icon"
                          />{" "}
                          <strong>Chiron</strong> in{" "}
                          <strong>{this.state.planets[11].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[11].house}
                            {this.state.planets[11].house === 1 ? "st" : null}
                            {this.state.planets[11].house === 2 ? "nd" : null}
                            {this.state.planets[11].house === 3 ? "rd" : null}
                            {this.state.planets[11].house > 3
                              ? "th"
                              : null}{" "}
                            house
                          </strong>
                          .
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body collapsible>
                        <p>{this.state.planets[11].interpretation}</p>
                        {this.state.planets[11].special.map((special, id) => {
                          if (special === "is in its joy.") {
                            return (
                              <p key={id}>
                                {`Chiron is in its joy in the ${
                                  this.state.planets[11].house
                                }${
                                  this.state.planets[11].house === 1 ? "st" : ""
                                }${
                                  this.state.planets[11].house === 2 ? "nd" : ""
                                }${
                                  this.state.planets[11].house === 3 ? "rd" : ""
                                }${
                                  this.state.planets[11].house > 3 ? "th" : ""
                                } house.`}
                              </p>
                            );
                          } else {
                            return (
                              <p key={id}>
                                {`Chiron in ${
                                  this.state.planets[11].sign
                                } ${special.toLowerCase()}`}
                              </p>
                            );
                          }
                        })}
                        {this.state.planets[11].is_retro === "true" ? (
                          <p>You were born when Chiron was retrograde.</p>
                        ) : null}
                      </Panel.Body>
                    </Panel>
                  </PanelGroup>
                </div>
                <div className="desktop-planets">
                  <table className="key-chart">
                    <tbody>
                      <tr>
                        <td className="icon-container">
                          <img
                            src={require("../Images/Mercury.svg")}
                            alt="Mercury Icon"
                          />
                        </td>
                        <td>
                          <strong>Mercury</strong> in{" "}
                          <strong>{this.state.planets[3].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[3].house}
                            {this.state.planets[3].house === 1 ? "st" : null}
                            {this.state.planets[3].house === 2 ? "nd" : null}
                            {this.state.planets[3].house === 3 ? "rd" : null}
                            {this.state.planets[3].house > 3 ? "th" : null}{" "}
                            house
                          </strong>
                          <p>{this.state.planets[3].interpretation}</p>
                          {this.state.planets[3].special.map((special, id) => {
                            if (special === "is in its joy.") {
                              return (
                                <p key={id}>
                                  {`Mercury is in its joy in the ${
                                    this.state.planets[3].house
                                  }${
                                    this.state.planets[3].house === 1
                                      ? "st"
                                      : ""
                                  }${
                                    this.state.planets[3].house === 2
                                      ? "nd"
                                      : ""
                                  }${
                                    this.state.planets[3].house === 3
                                      ? "rd"
                                      : ""
                                  }${
                                    this.state.planets[3].house > 3 ? "th" : ""
                                  } house.`}
                                </p>
                              );
                            } else {
                              return (
                                <p key={id}>
                                  {`Mercury in ${
                                    this.state.planets[3].sign
                                  } ${special.toLowerCase()}`}
                                </p>
                              );
                            }
                          })}
                          {this.state.planets[3].is_retro === "true" ? (
                            <p>You were born when Mercury was retrograde.</p>
                          ) : null}
                        </td>
                      </tr>
                      <tr>
                        <td className="icon-container">
                          <img
                            src={require("../Images/Venus.svg")}
                            alt="Venus Icon"
                          />
                        </td>
                        <td>
                          <strong>Venus</strong> in{" "}
                          <strong>{this.state.planets[5].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[5].house}
                            {this.state.planets[5].house === 1 ? "st" : null}
                            {this.state.planets[5].house === 2 ? "nd" : null}
                            {this.state.planets[5].house === 3 ? "rd" : null}
                            {this.state.planets[5].house > 3 ? "th" : null}{" "}
                            house
                          </strong>
                          <p>{this.state.planets[5].interpretation}</p>
                          {this.state.planets[5].special.map((special, id) => {
                            if (special === "is in its joy.") {
                              return (
                                <p key={id}>
                                  {`Venus is in its joy in the ${
                                    this.state.planets[5].house
                                  }${
                                    this.state.planets[5].house === 1
                                      ? "st"
                                      : ""
                                  }${
                                    this.state.planets[5].house === 2
                                      ? "nd"
                                      : ""
                                  }${
                                    this.state.planets[5].house === 3
                                      ? "rd"
                                      : ""
                                  }${
                                    this.state.planets[5].house > 3 ? "th" : ""
                                  } house.`}
                                </p>
                              );
                            } else {
                              return (
                                <p key={id}>
                                  {`Venus in ${
                                    this.state.planets[5].sign
                                  } ${special.toLowerCase()}`}
                                </p>
                              );
                            }
                          })}
                          {this.state.planets[5].is_retro === "true" ? (
                            <p>You were born when Venus was retrograde.</p>
                          ) : null}
                        </td>
                      </tr>
                      <tr>
                        <td className="icon-container">
                          <img
                            src={require("../Images/Mars.svg")}
                            alt="Mars Icon"
                          />
                        </td>
                        <td>
                          <strong>Mars</strong> in{" "}
                          <strong>{this.state.planets[2].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[2].house}
                            {this.state.planets[2].house === 1 ? "st" : null}
                            {this.state.planets[2].house === 2 ? "nd" : null}
                            {this.state.planets[2].house === 3 ? "rd" : null}
                            {this.state.planets[2].house > 3 ? "th" : null}{" "}
                            house
                          </strong>
                          <p>{this.state.planets[2].interpretation}</p>
                          {this.state.planets[2].special.map((special, id) => {
                            if (special === "is in its joy.") {
                              return (
                                <p key={id}>
                                  {`Mars is in its joy in the ${
                                    this.state.planets[2].house
                                  }${
                                    this.state.planets[2].house === 1
                                      ? "st"
                                      : ""
                                  }${
                                    this.state.planets[2].house === 2
                                      ? "nd"
                                      : ""
                                  }${
                                    this.state.planets[2].house === 3
                                      ? "rd"
                                      : ""
                                  }${
                                    this.state.planets[2].house > 3 ? "th" : ""
                                  } house.`}
                                </p>
                              );
                            } else {
                              return (
                                <p key={id}>
                                  {`Mars in ${
                                    this.state.planets[2].sign
                                  } ${special.toLowerCase()}`}
                                </p>
                              );
                            }
                          })}
                          {this.state.planets[2].is_retro === "true" ? (
                            <p>You were born when Mars was retrograde.</p>
                          ) : null}
                        </td>
                      </tr>
                      <tr>
                        <td className="icon-container">
                          <img
                            src={require("../Images/Jupiter.svg")}
                            alt="Jupiter Icon"
                          />
                        </td>
                        <td>
                          <strong>Jupiter</strong> in{" "}
                          <strong>{this.state.planets[4].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[4].house}
                            {this.state.planets[4].house === 1 ? "st" : null}
                            {this.state.planets[4].house === 2 ? "nd" : null}
                            {this.state.planets[4].house === 3 ? "rd" : null}
                            {this.state.planets[4].house > 3 ? "th" : null}{" "}
                            house
                          </strong>
                          <p>{this.state.planets[4].interpretation}</p>
                          {this.state.planets[4].special.map((special, id) => {
                            if (special === "is in its joy.") {
                              return (
                                <p key={id}>
                                  {`Jupiter is in its joy in the ${
                                    this.state.planets[4].house
                                  }${
                                    this.state.planets[4].house === 1
                                      ? "st"
                                      : ""
                                  }${
                                    this.state.planets[4].house === 2
                                      ? "nd"
                                      : ""
                                  }${
                                    this.state.planets[4].house === 3
                                      ? "rd"
                                      : ""
                                  }${
                                    this.state.planets[4].house > 3 ? "th" : ""
                                  } house.`}
                                </p>
                              );
                            } else {
                              return (
                                <p key={id}>
                                  {`Jupiter in ${
                                    this.state.planets[4].sign
                                  } ${special.toLowerCase()}`}
                                </p>
                              );
                            }
                          })}
                          {this.state.planets[4].is_retro === "true" ? (
                            <p>You were born when Jupiter was retrograde.</p>
                          ) : null}
                        </td>
                      </tr>
                      <tr>
                        <td className="icon-container">
                          <img
                            src={require("../Images/Saturn.svg")}
                            alt="Saturn Icon"
                          />
                        </td>
                        <td>
                          <strong>Saturn</strong> in{" "}
                          <strong>{this.state.planets[6].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[6].house}
                            {this.state.planets[6].house === 1 ? "st" : null}
                            {this.state.planets[6].house === 2 ? "nd" : null}
                            {this.state.planets[6].house === 3 ? "rd" : null}
                            {this.state.planets[6].house > 3 ? "th" : null}{" "}
                            house
                          </strong>
                          <p>{this.state.planets[6].interpretation}</p>
                          {this.state.planets[6].special.map((special, id) => {
                            if (special === "is in its joy.") {
                              return (
                                <p key={id}>
                                  {`Saturn is in its joy in the ${
                                    this.state.planets[6].house
                                  }${
                                    this.state.planets[6].house === 1
                                      ? "st"
                                      : ""
                                  }${
                                    this.state.planets[6].house === 2
                                      ? "nd"
                                      : ""
                                  }${
                                    this.state.planets[6].house === 3
                                      ? "rd"
                                      : ""
                                  }${
                                    this.state.planets[6].house > 3 ? "th" : ""
                                  } house.`}
                                </p>
                              );
                            } else {
                              return (
                                <p key={id}>
                                  {`Saturn in ${
                                    this.state.planets[6].sign
                                  } ${special.toLowerCase()}`}
                                </p>
                              );
                            }
                          })}
                          {this.state.planets[6].is_retro === "true" ? (
                            <p>You were born when Saturn was retrograde.</p>
                          ) : null}
                        </td>
                      </tr>
                      <tr>
                        <td className="icon-container">
                          <img
                            src={require("../Images/Uranus.svg")}
                            alt="Uranus Icon"
                          />
                        </td>
                        <td>
                          <strong>Uranus</strong> in{" "}
                          <strong>{this.state.planets[7].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[7].house}
                            {this.state.planets[7].house === 1 ? "st" : null}
                            {this.state.planets[7].house === 2 ? "nd" : null}
                            {this.state.planets[7].house === 3 ? "rd" : null}
                            {this.state.planets[7].house > 3 ? "th" : null}{" "}
                            house
                          </strong>
                          <p>{this.state.planets[7].interpretation}</p>
                          {this.state.planets[7].special.map((special, id) => {
                            if (special === "is in its joy.") {
                              return (
                                <p key={id}>
                                  {`Uranus is in its joy in the ${
                                    this.state.planets[7].house
                                  }${
                                    this.state.planets[7].house === 1
                                      ? "st"
                                      : ""
                                  }${
                                    this.state.planets[7].house === 2
                                      ? "nd"
                                      : ""
                                  }${
                                    this.state.planets[7].house === 3
                                      ? "rd"
                                      : ""
                                  }${
                                    this.state.planets[7].house > 3 ? "th" : ""
                                  } house.`}
                                </p>
                              );
                            } else {
                              return (
                                <p key={id}>
                                  {`Uranus in ${
                                    this.state.planets[7].sign
                                  } ${special.toLowerCase()}`}
                                </p>
                              );
                            }
                          })}
                          {this.state.planets[7].is_retro === "true" ? (
                            <p>You were born when Uranus was retrograde.</p>
                          ) : null}
                        </td>
                      </tr>
                      <tr>
                        <td className="icon-container">
                          <img
                            src={require("../Images/Neptune.svg")}
                            alt="Neptune Icon"
                          />
                        </td>
                        <td>
                          <strong>Neptune</strong> in{" "}
                          <strong>{this.state.planets[8].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[8].house}
                            {this.state.planets[8].house === 1 ? "st" : null}
                            {this.state.planets[8].house === 2 ? "nd" : null}
                            {this.state.planets[8].house === 3 ? "rd" : null}
                            {this.state.planets[8].house > 3 ? "th" : null}{" "}
                            house
                          </strong>
                          <p>{this.state.planets[8].interpretation}</p>
                          {this.state.planets[8].special.map((special, id) => {
                            if (special === "is in its joy.") {
                              return (
                                <p key={id}>
                                  {`Neptune is in its joy in the ${
                                    this.state.planets[8].house
                                  }${
                                    this.state.planets[8].house === 1
                                      ? "st"
                                      : ""
                                  }${
                                    this.state.planets[8].house === 2
                                      ? "nd"
                                      : ""
                                  }${
                                    this.state.planets[8].house === 3
                                      ? "rd"
                                      : ""
                                  }${
                                    this.state.planets[8].house > 3 ? "th" : ""
                                  } house.`}
                                </p>
                              );
                            } else {
                              return (
                                <p key={id}>
                                  {`Neptune in ${
                                    this.state.planets[8].sign
                                  } ${special.toLowerCase()}`}
                                </p>
                              );
                            }
                          })}
                          {this.state.planets[8].is_retro === "true" ? (
                            <p>You were born when Neptune was retrograde.</p>
                          ) : null}
                        </td>
                      </tr>
                      <tr>
                        <td className="icon-container">
                          <img
                            src={require("../Images/Pluto.svg")}
                            alt="Pluto Icon"
                          />
                        </td>
                        <td>
                          <strong>Pluto</strong> in{" "}
                          <strong>{this.state.planets[9].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[9].house}
                            {this.state.planets[9].house === 1 ? "st" : null}
                            {this.state.planets[9].house === 2 ? "nd" : null}
                            {this.state.planets[9].house === 3 ? "rd" : null}
                            {this.state.planets[9].house > 3 ? "th" : null}{" "}
                            house
                          </strong>
                          <p>{this.state.planets[9].interpretation}</p>
                          {this.state.planets[9].special.map((special, id) => {
                            if (special === "is in its joy.") {
                              return (
                                <p key={id}>
                                  {`Pluto is in its joy in the ${
                                    this.state.planets[9].house
                                  }${
                                    this.state.planets[9].house === 1
                                      ? "st"
                                      : ""
                                  }${
                                    this.state.planets[9].house === 2
                                      ? "nd"
                                      : ""
                                  }${
                                    this.state.planets[9].house === 3
                                      ? "rd"
                                      : ""
                                  }${
                                    this.state.planets[9].house > 3 ? "th" : ""
                                  } house.`}
                                </p>
                              );
                            } else {
                              return (
                                <p key={id}>
                                  {`Pluto in ${
                                    this.state.planets[9].sign
                                  } ${special.toLowerCase()}`}
                                </p>
                              );
                            }
                          })}
                          {this.state.planets[9].is_retro === "true" ? (
                            <p>You were born when Pluto was retrograde.</p>
                          ) : null}
                        </td>
                      </tr>
                      <tr>
                        <td className="icon-container">
                          <img
                            src={require("../Images/chiron.svg")}
                            alt="Chiron Icon"
                          />
                        </td>
                        <td>
                          <strong>Chiron</strong> in{" "}
                          <strong>{this.state.planets[11].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[11].house}
                            {this.state.planets[11].house === 1 ? "st" : null}
                            {this.state.planets[11].house === 2 ? "nd" : null}
                            {this.state.planets[11].house === 3 ? "rd" : null}
                            {this.state.planets[11].house > 3
                              ? "th"
                              : null}{" "}
                            house
                          </strong>
                          <p>{this.state.planets[11].interpretation}</p>
                          {this.state.planets[11].special.map((special, id) => {
                            if (special === "is in its joy.") {
                              return (
                                <p key={id}>
                                  {`Chiron is in its joy in the ${
                                    this.state.planets[11].house
                                  }${
                                    this.state.planets[11].house === 1
                                      ? "st"
                                      : ""
                                  }${
                                    this.state.planets[11].house === 2
                                      ? "nd"
                                      : ""
                                  }${
                                    this.state.planets[11].house === 3
                                      ? "rd"
                                      : ""
                                  }${
                                    this.state.planets[11].house > 3 ? "th" : ""
                                  } house.`}
                                </p>
                              );
                            } else {
                              return (
                                <p key={id}>
                                  {`Chiron in ${
                                    this.state.planets[11].sign
                                  } ${special.toLowerCase()}`}
                                </p>
                              );
                            }
                          })}
                          {this.state.planets[11].is_retro === "true" ? (
                            <p>You were born when Chiron was retrograde.</p>
                          ) : null}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* POINTS AND NODES */}
              <div className="section-wrapper">
                <p>
                  <strong>Points and Nodes</strong>
                </p>
                {/* MOBILE POINTS */}
                <div className="panel-group">
                  <PanelGroup accordion id="dc" className="accordion-planets">
                    <Panel eventKey="1">
                      <Panel.Heading>
                        <Panel.Title toggle>
                          <img
                            src={require("../Images/dc.png")}
                            alt="DC Icon"
                          />{" "}
                          {this.state.dcTitle.split(" ").map((word, id) => {
                            if (
                              word === "DC" ||
                              word === "Taurus" ||
                              word === "Aries" ||
                              word === "Pisces" ||
                              word === "Aquarius" ||
                              word === "Capricorn" ||
                              word === "Sagittarius" ||
                              word === "Scorpio" ||
                              word === "Libra" ||
                              word === "Virgo" ||
                              word === "Leo" ||
                              word === "Cancer" ||
                              word === "Gemini" ||
                              word === "house" ||
                              word === "1st" ||
                              word === "2nd" ||
                              word === "3rd" ||
                              word === "11th" ||
                              word === "12th" ||
                              word === "4th" ||
                              word === "5th" ||
                              word === "6th" ||
                              word === "7th" ||
                              word === "8th" ||
                              word === "9th" ||
                              word === "10th"
                            ) {
                              return <strong key={id}>{word} </strong>;
                            } else {
                              return <span key={id}>{word} </span>;
                            }
                          })}
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body collapsible>{this.state.DC}</Panel.Body>
                    </Panel>
                  </PanelGroup>
                  <PanelGroup accordion id="mc" className="accordion-planets">
                    <Panel eventKey="1">
                      <Panel.Heading>
                        <Panel.Title toggle>
                          <img
                            src={require("../Images/mc.png")}
                            alt="MC Icon"
                          />{" "}
                          {this.state.mcTitle.split(" ").map((word, id) => {
                            if (
                              word === "MC" ||
                              word === "Taurus" ||
                              word === "Aries" ||
                              word === "Pisces" ||
                              word === "Aquarius" ||
                              word === "Capricorn" ||
                              word === "Sagittarius" ||
                              word === "Scorpio" ||
                              word === "Libra" ||
                              word === "Virgo" ||
                              word === "Leo" ||
                              word === "Cancer" ||
                              word === "Gemini" ||
                              word === "house" ||
                              word === "1st" ||
                              word === "2nd" ||
                              word === "3rd" ||
                              word === "11th" ||
                              word === "12th" ||
                              word === "4th" ||
                              word === "5th" ||
                              word === "6th" ||
                              word === "7th" ||
                              word === "8th" ||
                              word === "9th" ||
                              word === "10th"
                            ) {
                              return <strong key={id}>{word} </strong>;
                            } else {
                              return <span key={id}>{word} </span>;
                            }
                          })}
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body collapsible>{this.state.MC}</Panel.Body>
                    </Panel>
                  </PanelGroup>
                  <PanelGroup accordion id="ic" className="accordion-planets">
                    <Panel eventKey="1">
                      <Panel.Heading>
                        <Panel.Title toggle>
                          <img
                            src={require("../Images/ic.png")}
                            alt="IC Icon"
                          />{" "}
                          {this.state.icTitle.split(" ").map((word, id) => {
                            if (
                              word === "IC" ||
                              word === "Taurus" ||
                              word === "Aries" ||
                              word === "Pisces" ||
                              word === "Aquarius" ||
                              word === "Capricorn" ||
                              word === "Sagittarius" ||
                              word === "Scorpio" ||
                              word === "Libra" ||
                              word === "Virgo" ||
                              word === "Leo" ||
                              word === "Cancer" ||
                              word === "Gemini" ||
                              word === "house" ||
                              word === "1st" ||
                              word === "2nd" ||
                              word === "3rd" ||
                              word === "11th" ||
                              word === "12th" ||
                              word === "4th" ||
                              word === "5th" ||
                              word === "6th" ||
                              word === "7th" ||
                              word === "8th" ||
                              word === "9th" ||
                              word === "10th"
                            ) {
                              return <strong key={id}>{word} </strong>;
                            } else {
                              return <span key={id}>{word} </span>;
                            }
                          })}
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body collapsible>{this.state.IC}</Panel.Body>
                    </Panel>
                  </PanelGroup>
                  <PanelGroup
                    accordion
                    id="north-node"
                    className="accordion-planets"
                  >
                    <Panel eventKey="1">
                      <Panel.Heading>
                        <Panel.Title toggle>
                          <img
                            src={require("../Images/northnode.svg")}
                            alt="North Node Icon"
                          />{" "}
                          <strong>North Node</strong> in{" "}
                          <strong>{this.state.planets[10].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[10].house}
                            {this.state.planets[10].house === 1 ? "st" : null}
                            {this.state.planets[10].house === 2 ? "nd" : null}
                            {this.state.planets[10].house === 3 ? "rd" : null}
                            {this.state.planets[10].house > 3
                              ? "th"
                              : null}{" "}
                            house
                          </strong>
                          .
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body collapsible>
                        <p>North {this.state.planets[10].interpretation}</p>
                        {this.state.planets[10].special.map((special, id) => {
                          if (special === "is in its joy.") {
                            return (
                              <p key={id}>
                                {`North Node is in its joy in the ${
                                  this.state.planets[10].house
                                }${
                                  this.state.planets[10].house === 1 ? "st" : ""
                                }${
                                  this.state.planets[10].house === 2 ? "nd" : ""
                                }${
                                  this.state.planets[10].house === 3 ? "rd" : ""
                                }${
                                  this.state.planets[10].house > 3 ? "th" : ""
                                } house.`}
                              </p>
                            );
                          } else {
                            return (
                              <p key={id}>
                                {`North Node in ${
                                  this.state.planets[10].sign
                                } ${special.toLowerCase()}`}
                              </p>
                            );
                          }
                        })}
                      </Panel.Body>
                    </Panel>
                  </PanelGroup>
                  <PanelGroup
                    accordion
                    id="south-node"
                    className="accordion-planets"
                  >
                    <Panel eventKey="1">
                      <Panel.Heading>
                        <Panel.Title toggle>
                          <img
                            src={require("../Images/southnode.svg")}
                            alt="South Node Icon"
                          />{" "}
                          <strong>South Node</strong> in{" "}
                          <strong>
                            {this.state.southNode.sign_id === 1
                              ? "Aries"
                              : null}
                            {this.state.southNode.sign_id === 2
                              ? "Taurus"
                              : null}
                            {this.state.southNode.sign_id === 3
                              ? "Gemini"
                              : null}
                            {this.state.southNode.sign_id === 4
                              ? "Cancer"
                              : null}
                            {this.state.southNode.sign_id === 5 ? "Leo" : null}
                            {this.state.southNode.sign_id === 6
                              ? "Virgo"
                              : null}
                            {this.state.southNode.sign_id === 7
                              ? "Libra"
                              : null}
                            {this.state.southNode.sign_id === 8
                              ? "Scorpio"
                              : null}
                            {this.state.southNode.sign_id === 9
                              ? "Sagittarius"
                              : null}
                            {this.state.southNode.sign_id === 10
                              ? "Capricorn"
                              : null}
                            {this.state.southNode.sign_id === 11
                              ? "Aquarius"
                              : null}
                            {this.state.southNode.sign_id === 12
                              ? "Pisces"
                              : null}
                          </strong>{" "}
                          in the{" "}
                          <strong>
                            {this.state.southNode.house}
                            {this.state.southNode.house === 1 ? "st" : null}
                            {this.state.southNode.house === 2 ? "nd" : null}
                            {this.state.southNode.house === 3 ? "rd" : null}
                            {this.state.southNode.house > 3 ? "th" : null} house
                          </strong>
                          .
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body collapsible>
                        <p>{this.state.southNode.description}</p>
                      </Panel.Body>
                    </Panel>
                  </PanelGroup>
                </div>
                <div className="desktop-nodes">
                  <table className="key-chart">
                    <tbody>
                      <tr>
                        <td className="icon-container">
                          <img
                            src={require("../Images/dc.png")}
                            alt="DC Icon"
                          />
                        </td>
                        <td>
                          <p>
                            {" "}
                            {this.state.dcTitle.split(" ").map((word, id) => {
                              if (
                                word === "DC" ||
                                word === "Taurus" ||
                                word === "Aries" ||
                                word === "Pisces" ||
                                word === "Aquarius" ||
                                word === "Capricorn" ||
                                word === "Sagittarius" ||
                                word === "Scorpio" ||
                                word === "Libra" ||
                                word === "Virgo" ||
                                word === "Leo" ||
                                word === "Cancer" ||
                                word === "Gemini" ||
                                word === "house" ||
                                word === "1st" ||
                                word === "2nd" ||
                                word === "3rd" ||
                                word === "11th" ||
                                word === "12th" ||
                                word === "4th" ||
                                word === "5th" ||
                                word === "6th" ||
                                word === "7th" ||
                                word === "8th" ||
                                word === "9th" ||
                                word === "10th"
                              ) {
                                return <strong key={id}>{word} </strong>;
                              } else {
                                return <span key={id}>{word} </span>;
                              }
                            })}
                          </p>

                          {this.state.DC}
                        </td>
                      </tr>
                      <tr>
                        <td className="icon-container">
                          <img
                            src={require("../Images/mc.png")}
                            alt="MC Icon"
                          />
                        </td>
                        <td>
                          <p>
                            {this.state.mcTitle.split(" ").map((word, id) => {
                              if (
                                word === "MC" ||
                                word === "Taurus" ||
                                word === "Aries" ||
                                word === "Pisces" ||
                                word === "Aquarius" ||
                                word === "Capricorn" ||
                                word === "Sagittarius" ||
                                word === "Scorpio" ||
                                word === "Libra" ||
                                word === "Virgo" ||
                                word === "Leo" ||
                                word === "Cancer" ||
                                word === "Gemini" ||
                                word === "house" ||
                                word === "1st" ||
                                word === "2nd" ||
                                word === "3rd" ||
                                word === "11th" ||
                                word === "12th" ||
                                word === "4th" ||
                                word === "5th" ||
                                word === "6th" ||
                                word === "7th" ||
                                word === "8th" ||
                                word === "9th" ||
                                word === "10th"
                              ) {
                                return <strong key={id}>{word} </strong>;
                              } else {
                                return <span key={id}>{word} </span>;
                              }
                            })}
                          </p>

                          {this.state.MC}
                        </td>
                      </tr>
                      <tr>
                        <td className="icon-container">
                          <img
                            src={require("../Images/ic.png")}
                            alt="IC Icon"
                          />
                        </td>
                        <td>
                          <p>
                            {this.state.icTitle.split(" ").map((word, id) => {
                              if (
                                word === "IC" ||
                                word === "Taurus" ||
                                word === "Aries" ||
                                word === "Pisces" ||
                                word === "Aquarius" ||
                                word === "Capricorn" ||
                                word === "Sagittarius" ||
                                word === "Scorpio" ||
                                word === "Libra" ||
                                word === "Virgo" ||
                                word === "Leo" ||
                                word === "Cancer" ||
                                word === "Gemini" ||
                                word === "house" ||
                                word === "1st" ||
                                word === "2nd" ||
                                word === "3rd" ||
                                word === "11th" ||
                                word === "12th" ||
                                word === "4th" ||
                                word === "5th" ||
                                word === "6th" ||
                                word === "7th" ||
                                word === "8th" ||
                                word === "9th" ||
                                word === "10th"
                              ) {
                                return <strong key={id}>{word} </strong>;
                              } else {
                                return <span key={id}>{word} </span>;
                              }
                            })}
                          </p>
                          {this.state.IC}
                        </td>
                      </tr>
                      <tr>
                        <td className="icon-container">
                          <img
                            src={require("../Images/northnode.svg")}
                            alt="North Node Icon"
                          />
                        </td>
                        <td>
                          <strong>North Node</strong> in{" "}
                          <strong>{this.state.planets[10].sign}</strong> in the{" "}
                          <strong>
                            {this.state.planets[10].house}
                            {this.state.planets[10].house === 1 ? "st" : null}
                            {this.state.planets[10].house === 2 ? "nd" : null}
                            {this.state.planets[10].house === 3 ? "rd" : null}
                            {this.state.planets[10].house > 3
                              ? "th"
                              : null}{" "}
                            house
                          </strong>
                          <p>North {this.state.planets[10].interpretation}</p>
                          {this.state.planets[10].special.map((special, id) => {
                            if (special === "is in its joy.") {
                              return (
                                <p key={id}>
                                  {`North Node is in its joy in the ${
                                    this.state.planets[10].house
                                  }${
                                    this.state.planets[10].house === 1
                                      ? "st"
                                      : ""
                                  }${
                                    this.state.planets[10].house === 2
                                      ? "nd"
                                      : ""
                                  }${
                                    this.state.planets[10].house === 3
                                      ? "rd"
                                      : ""
                                  }${
                                    this.state.planets[10].house > 3 ? "th" : ""
                                  } house.`}
                                </p>
                              );
                            } else {
                              return (
                                <p key={id}>
                                  {`North Node in ${
                                    this.state.planets[10].sign
                                  } ${special.toLowerCase()}`}
                                </p>
                              );
                            }
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td className="icon-container">
                          <img
                            src={require("../Images/southnode.svg")}
                            alt="South Node Icon"
                          />
                        </td>
                        <td>
                          <strong>South Node</strong> in{" "}
                          <strong>
                            {this.state.southNode.sign_id === 1
                              ? "Aries"
                              : null}
                            {this.state.southNode.sign_id === 2
                              ? "Taurus"
                              : null}
                            {this.state.southNode.sign_id === 3
                              ? "Gemini"
                              : null}
                            {this.state.southNode.sign_id === 4
                              ? "Cancer"
                              : null}
                            {this.state.southNode.sign_id === 5 ? "Leo" : null}
                            {this.state.southNode.sign_id === 6
                              ? "Virgo"
                              : null}
                            {this.state.southNode.sign_id === 7
                              ? "Libra"
                              : null}
                            {this.state.southNode.sign_id === 8
                              ? "Scorpio"
                              : null}
                            {this.state.southNode.sign_id === 9
                              ? "Sagittarius"
                              : null}
                            {this.state.southNode.sign_id === 10
                              ? "Capricorn"
                              : null}
                            {this.state.southNode.sign_id === 11
                              ? "Aquarius"
                              : null}
                            {this.state.southNode.sign_id === 12
                              ? "Pisces"
                              : null}
                          </strong>{" "}
                          in the{" "}
                          <strong>
                            {this.state.southNode.house}
                            {this.state.southNode.house === 1 ? "st" : null}
                            {this.state.southNode.house === 2 ? "nd" : null}
                            {this.state.southNode.house === 3 ? "rd" : null}
                            {this.state.southNode.house > 3 ? "th" : null} house
                          </strong>
                          <p>{this.state.southNode.description}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="form-wrapper">
              <form className="api-input-form">
                <label>Name</label>
                <FormControl
                  type="text"
                  title="Name"
                  name="name"
                  placeholder="Your name"
                  value={this.state.name}
                  onChange={this.handleTextChange}
                  required
                />

                <label>Email</label>
                <FormControl
                  type="email"
                  title="Email"
                  name="email"
                  placeholder="youremail@email.com"
                  value={this.state.email}
                  onChange={this.handleTextChange}
                  required
                />

                <div className="time-wrapper">
                  <div style={{ width: "90px" }}>
                    <label>Birth Year</label>
                    <FormControl
                      type="number"
                      placeholder="YYYY"
                      name="year"
                        value={this.state.year}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div>
                    <label>Birth Date </label>

                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      name="day"
                      value={this.state.day}
                      onChange={this.handleChange}
                    >
                      <option>Select:</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                    </FormControl>
                  </div>
                  <div>
                    <label>Birth Month</label>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      name="month"
                      value={this.state.month}
                      onChange={this.handleChange}
                    >
                      <option>Select:</option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </FormControl>
                  </div>
                </div>

                <div className="time-wrapper">
                  <div>
                    <label>Birth Hour</label>
                    <FormControl
                      type="number"
                      title="Birth hour"
                      value={this.state.hour}
                      onChange={this.handleChange}
                      name="hour"
                      placeholder="HH"
                      min="0"
                      max="24"
                      required
                    />
                  </div>
                  <div>
                    <label>Birth Minute</label>
                    <FormControl
                      type="number"
                      title="Birth minute"
                        value={this.state.min}
                      onChange={this.handleChange}
                      name="min"
                      placeholder="MM"
                      min="0"
                      max="59"
                      required
                    />
                  </div>

                  <div>
                    <label>Select AM/PM</label>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      name="am_pm"
                      value={this.state.am_pm}
                      onChange={this.handleTextChange}
                    >
                      <option value="AM">AM</option>
                      <option value="pm">PM</option>
                    </FormControl>
                  </div>
                </div>

                <label>Birth Place</label>
                <PlacesAutocomplete
                  value={this.state.address}
                  onChange={this.handleAddressChange}
                  onSelect={this.handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <FormControl
                        {...getInputProps({
                          placeholder: "Search Places ...",
                          className: "location-search-input",
                        })}
                        value={this.state.address}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer",
                              }
                            : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>

                <label>Pick your house system</label>
                <FormControl
                  componentClass="select"
                  placeholder="select"
                  name="house_type"
                  onChange={this.handleTextChange}
                >
                  <option>
                      {
                        this.state.house_type === "whole_sign" ? "Whole Sign (what I use)" :
                        this.state.house_type === "placidus" ? "Placidus" :
                        this.state.house_type === "campanus" ? "Campanus" :
                        this.state.house_type === "horizontal" ? "Horizon" :
                        this.state.house_type === "koch" ? "Koch" :
                        this.state.house_type === "porphyry" ? "Porphyry" :
                        this.state.house_type === "topocentric" ? "Topocentric" :
                        this.state.house_type === "equal" ? "Equal"
                        : ""}
                  </option>
                    {houses.map((house) => (
                      house.value !== this.state.house_type ? <option value={house.value}>{house.label}</option> : ""
                    ))}
                </FormControl>

                <p className="terms-text">
                  Your personal information will be used to produce your chart
                  and deliver ongoing communications from Chani Nicholas, as
                  further described in our Privacy Policy. By proceeding, you
                  agree to our{" "}
                  <a
                    href="https://chaninicholas.com/terms-of-service/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://chaninicholas.com/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>
                <FormControl
                  onClick={this.handleSubmit}
                  type="submit"
                  value={
                    this.state.getTimezone === true
                      ? "Calculating..."
                      : "Submit"
                  }
                  disabled={this.state.getTimezone}
                  className="submit-button"
                />
                <div style={{marginTop: '50px'}}>
                  <p className="terms-text"><strong>Who built this?</strong> <a href="https://anniecannons.org">AnnieCannons</a> is a queer-led software development agency that trains all of its engineers from among survivors of human trafficking and gender-based violence and pays these newly trained engineers a living wage to build software, like this chart tool. It has been an incredible experience to work with this team and I am so proud of the chart tool we built for you.</p>
              </div>
              </form> 
            </div>
          )}
        </div>
        <Footer />
      </div>
    );
  }
}

export default Chart;
