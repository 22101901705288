import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <Grid>
          <Row>
            <Col md={8}>
              <div className="fl-rich-text">
                <p>
                  &#x000A9;&nbsp;2020 CHANI NICHOLAS |{" "}
                  <a
                    href="mailto:info@chaninicholas.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    info@chaninicholas.com
                  </a>{" "}
                  |{" "}
                  <a
                    href="mailto:media@chaninicholas.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    media@chaninicholas.com
                  </a>
                  <br />
                  <a href="https://chaninicholas.com/terms-of-service/">
                    Terms of Service
                  </a>{" "}
                  <a href="https://chaninicholas.com/privacy-policy/">
                    Privacy Policy
                  </a>
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="fl-html">
                <a
                  href="https://www.facebook.com/chani.nicholas/"
                  target="blank"
                >
                  <img
                    src="https://chaninicholas.com/wp-content/uploads/fb-white.png"
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://www.instagram.com/chaninicholas/"
                  target="blank"
                >
                  <img
                    src="https://chaninicholas.com/wp-content/uploads/insta-white.png"
                    alt="Instagram"
                  />
                </a>
                <a href="https://www.twitter.com/chaninicholas/" target="blank">
                  <img
                    src="https://chaninicholas.com/wp-content/uploads/twit-white.png"
                    alt="Twitter"
                  />
                </a>
                <a href="mailto:info@chaninicholas.com" target="blank">
                  <img
                    styles={{ height: "26px" }}
                    src="https://chaninicholas.com/wp-content/uploads/email-white.png"
                    alt="Email"
                  />
                </a>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Footer;
