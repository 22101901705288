import React, { Component } from "react";
import { Navbar, Nav, NavItem } from "react-bootstrap";

class Header extends Component {
  render() {
    return (
      <div className="header-wrapper">
        {/* Top Bar */}
        <div className="top-bar">

          <div className="connect-bar">
            <div className="textwidget">
              <div>CONNECT</div>
              <div className="buttons">
                <a
                  href="mailto:info@chaninicholas.com"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ height: "20px", marginTop: "-3px" }}
                    src="https://chaninicholas.com/wp-content/uploads/email-white.png"
                    alt="Email Chani"
                  />
                </a>{" "}
                <a
                  href="https://twitter.com/chaninicholas/"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://chaninicholas.com/wp-content/uploads/twit-white.png"
                    alt="Twitter"
                  />
                </a>{" "}
                <a
                  href="https://www.instagram.com/chaninicholas/"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://chaninicholas.com/wp-content/uploads/2019/11/new-insta-white2.png"
                    alt="Instagram"
                  />
                </a>{" "}
                <a
                  href="https://www.facebook.com/chani.nicholas/"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://chaninicholas.com/wp-content/uploads/fb-white.png"
                    alt="Facebook"
                  />
                </a>{" "}
              </div>
            </div>
          </div>
        </div>

        {/* Main Logo */}
        <div className="cn-logo">
          <a href="https://chaninicholas.com/">
            <img
              src={require("../Images/Chani-Nicholas-Regular-Logo-1-Line-1600x182.png")}
              alt="Chani Nicolas Logo"
            />
          </a>
        </div>

        {/* Nav Links */}

        <Navbar collapseOnSelect>
          <Navbar.Header>
            <Navbar.Toggle />
            <Navbar.Brand>MENU</Navbar.Brand>
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav>
              <NavItem eventKey={1} href="https://chaninicholas.com/">
                HOME
              </NavItem>
              <NavItem
                eventKey={2}
                href="https://chaninicholas.com/about-chani-nicholas/"
              >
                ABOUT
              </NavItem>
              <NavItem
                eventKey={3}
                href="https://chaninicholas.com/horoscopes/"
              >
                HOROSCOPES
              </NavItem>
              <NavItem
                eventKey={4}
                href="https://chaninicholas.com/you-were-born-for-this/"
              >
                MY BOOK
              </NavItem>
              <NavItem
                eventKey={6}
                href="https://chani-nicholas.myshopify.com/?utm_source=mainmenu"
              >
                SHOP
              </NavItem>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Header;
