const prod = {
  apiGateway: {
    REGION: "us-west-2",
    DEV_CHANI_CHART_BACKEND_URL: process.env.REACT_APP_DEV_CHANI_CHART_BACKEND_URL,
    CHANI_APP_LAMBDAS_URL: process.env.REACT_APP_CHANI_APP_LAMBDAS_URL
  },
};

const config = prod;

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
